export const Ecuador: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<svg
			viewBox="0 0 91 91"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M90.9998 60.4053H0V73.7414C0 74.6078 0.702583 75.3104 1.56904 75.3104H89.431C90.2974 75.3104 91 74.6078 91 73.7414L90.9998 60.4053Z"
				fill="#FF4B55"
			/>
			<path
				d="M90.9998 45.5001H0V17.2587C0 16.3923 0.702583 15.6897 1.56904 15.6897H89.431C90.2974 15.6897 91 16.3923 91 17.2587L90.9998 45.5001Z"
				fill="#FFE15A"
			/>
			<path d="M90.9998 45.5H0V60.4048H90.9998V45.5Z" fill="#41479B" />
			<path
				d="M43.8626 46.3804L55.3229 36.5574V50.7738C55.3229 53.1698 53.3805 55.1119 50.9848 55.1119C49.9131 55.1119 48.8792 54.7152 48.0826 53.9984L43.8626 50.2006V46.3804Z"
				fill="#FFE15A"
			/>
			<path
				d="M49.9079 51.9704L46.5914 48.9856V44.0419L52.5943 38.8965V42.4904V50.7741C52.5943 51.6615 51.8722 52.3837 50.9848 52.3837C50.5865 52.3837 50.204 52.2369 49.9079 51.9704Z"
				fill="#FF4B55"
			/>
			<path
				d="M48.2285 42.6387V48.2582L50.9571 50.7219V42.4564V40.3001L52.5943 38.8967V42.4907V50.7743C52.5943 51.6618 51.8722 52.3839 50.9848 52.3839C50.5865 52.3839 50.204 52.2373 49.9079 51.9707L46.5914 48.9858V44.0421L48.2285 42.6387Z"
				fill="#41479B"
			/>
			<path
				d="M47.1372 46.3804L35.6769 36.5574V50.7738C35.6769 53.1698 37.6194 55.1119 40.015 55.1119C41.0868 55.1119 42.1207 54.7152 42.9173 53.9984L47.1372 50.2006V46.3804Z"
				fill="#FFE15A"
			/>
			<path
				d="M41.0919 51.9704L44.4085 48.9856V44.0419L38.4055 38.8965V42.4904V50.7741C38.4055 51.6615 39.1276 52.3837 40.015 52.3837C40.4133 52.3837 40.7958 52.2369 41.0919 51.9704Z"
				fill="#FF4B55"
			/>
			<path
				d="M42.7714 42.6387V48.2582L40.0428 50.7219V42.4564V40.3001L38.4055 38.8967V42.4907V50.7743C38.4055 51.6618 39.1277 52.3839 40.0151 52.3839C40.4134 52.3839 40.7959 52.2373 41.092 51.9707L44.4085 48.9858V44.0421L42.7714 42.6387Z"
				fill="#41479B"
			/>
			<path
				d="M55.7114 36.6064H54.9269V41.3073H55.7114V36.6064Z"
				fill="#41479B"
			/>
			<path
				d="M36.0665 36.6064H35.282V41.3073H36.0665V36.6064Z"
				fill="#41479B"
			/>
			<path
				d="M43.8626 34.3748C43.8626 34.3748 44.9541 29.1903 41.134 29.8724C37.314 30.5545 37.314 31.6458 32.9481 33.8289C32.9481 33.8289 37.8596 34.9204 40.0426 34.3748C40.0426 34.3748 41.6798 36.5575 43.3169 37.1033H44.9542L43.8626 34.3748Z"
				fill="#5A4646"
			/>
			<path
				d="M47.1371 34.3748C47.1371 34.3748 46.0457 29.1903 49.8657 29.8724C53.6858 30.5545 53.6858 31.6458 58.0516 33.8289C58.0516 33.8289 53.1401 34.9204 50.9572 34.3748C50.9572 34.3748 49.3199 36.5575 47.6828 37.1033H46.0455L47.1371 34.3748Z"
				fill="#5A4646"
			/>
			<path
				d="M47.1371 37.1034H43.8627V34.3546C43.8627 33.4615 44.5866 32.7375 45.4798 32.7375H45.5201C46.4132 32.7375 47.1371 33.4615 47.1371 34.3546V37.1034Z"
				fill="#5A4646"
			/>
			<path
				d="M45.4999 35.4654C46.1027 35.4654 46.5914 34.9767 46.5914 34.3739C46.5914 33.7711 46.1027 33.2825 45.4999 33.2825C44.8971 33.2825 44.4084 33.7711 44.4084 34.3739C44.4084 34.9767 44.8971 35.4654 45.4999 35.4654Z"
				fill="#E6E1CD"
			/>
			<path
				d="M43.1579 33.6699L45.21 34.7658C45.3822 34.8579 45.5943 34.8263 45.732 34.6882C45.9051 34.5151 45.9051 34.2346 45.732 34.0613L44.4083 32.7375H44.0899C43.5752 32.7375 43.1579 33.1549 43.1579 33.6699Z"
				fill="#FFD0AF"
			/>
			<path
				d="M43.8696 33.8288H42.2257L42.945 32.9867C43.0801 32.8287 43.2775 32.7375 43.4857 32.7375H44.4085V33.2899C44.4085 33.5875 44.1671 33.8288 43.8696 33.8288Z"
				fill="#FFAF94"
			/>
			<path
				d="M50.154 37.1047C49.7858 36.6678 49.7072 36.0886 49.8867 35.5866C50.4118 35.4949 50.9693 35.6703 51.3374 36.1076C51.652 36.481 51.7432 36.9557 51.6545 37.4C51.7636 37.5852 51.8969 37.8234 52.0435 38.1046C52.0508 38.0646 52.0492 38.025 52.0606 37.985C52.1893 37.5325 52.5481 37.2122 52.9733 37.1038C53.2777 37.4198 53.4146 37.881 53.2859 38.3333C53.1753 38.7226 52.8899 39.0046 52.543 39.1475C52.68 39.4619 52.8156 39.8011 52.9423 40.1588C52.9496 40.1379 52.9502 40.1165 52.9587 40.0957C53.1364 39.6601 53.5285 39.3814 53.9629 39.3205C54.2305 39.6679 54.3159 40.1414 54.1383 40.577C53.9828 40.9581 53.6602 41.2094 53.2916 41.3095C53.4814 42.0583 53.6071 42.8528 53.6071 43.6526H53.0612C53.0612 42.9555 52.9542 42.2573 52.7927 41.5894C52.4559 41.6982 52.0796 41.6749 51.7538 41.4755C51.3564 41.232 51.1467 40.804 51.1565 40.3695C51.5393 40.1633 52.0158 40.1556 52.4132 40.3993C52.4276 40.408 52.4372 40.4208 52.4511 40.4301C52.3341 40.0941 52.2097 39.7755 52.0819 39.4765C51.7544 39.6313 51.3689 39.6551 51.0152 39.4891C50.5932 39.2908 50.3378 38.8886 50.2996 38.4556C50.6561 38.2093 51.1273 38.1497 51.5478 38.3454C51.3977 38.0566 51.262 37.8147 51.1558 37.6341C50.7798 37.5908 50.4178 37.4176 50.154 37.1047Z"
				fill="#8CB400"
			/>
			<path
				d="M40.8458 37.1047C41.214 36.6678 41.2926 36.0886 41.1131 35.5866C40.5881 35.4949 40.0305 35.6703 39.6624 36.1076C39.3478 36.481 39.2567 36.9557 39.3453 37.4C39.2362 37.5852 39.1029 37.8234 38.9563 38.1046C38.949 38.0646 38.9506 38.025 38.9392 37.985C38.8106 37.5325 38.4517 37.2122 38.0266 37.1038C37.7221 37.4198 37.5853 37.881 37.7139 38.3333C37.8245 38.7226 38.1099 39.0046 38.4569 39.1475C38.3198 39.4619 38.1842 39.8011 38.0575 40.1588C38.0502 40.1379 38.0497 40.1165 38.0411 40.0957C37.8634 39.6601 37.4713 39.3814 37.0369 39.3205C36.7693 39.6679 36.684 40.1414 36.8617 40.577C37.0172 40.9581 37.3398 41.2094 37.7084 41.3095C37.5186 42.0583 37.3929 42.8528 37.3929 43.6526H37.9388C37.9388 42.9555 38.0458 42.2573 38.2073 41.5894C38.5441 41.6982 38.9204 41.6749 39.2462 41.4755C39.6438 41.232 39.8533 40.804 39.8435 40.3695C39.4607 40.1633 38.9842 40.1556 38.5868 40.3993C38.5724 40.408 38.5628 40.4208 38.5489 40.4301C38.6659 40.0941 38.7903 39.7755 38.9181 39.4765C39.2456 39.6313 39.6311 39.6551 39.9848 39.4891C40.4068 39.2908 40.6622 38.8886 40.7004 38.4556C40.3439 38.2093 39.8727 38.1497 39.4522 38.3454C39.6024 38.0566 39.738 37.8147 39.8442 37.6341C40.22 37.5908 40.582 37.4176 40.8458 37.1047Z"
				fill="#8CB400"
			/>
			<path
				d="M45.4999 49.1091L34.0396 39.2861V53.5025C34.0396 55.8986 35.9821 57.8407 38.3777 57.8407C39.4495 57.8407 40.4834 57.444 41.28 56.7272L45.4999 52.9294V49.1091Z"
				fill="#FFE15A"
			/>
			<path
				d="M39.4546 54.6989L42.7712 51.7141V46.7704L36.7682 41.625V45.219V53.5026C36.7682 54.39 37.4903 55.1122 38.3777 55.1122C38.7762 55.1122 39.1585 54.9655 39.4546 54.6989Z"
				fill="#FF4B55"
			/>
			<path
				d="M41.1341 45.3673V50.9867L38.4055 53.4504V45.1849V43.0286L36.7682 41.6252V45.2192V53.5029C36.7682 54.3903 37.4903 55.1124 38.3777 55.1124C38.7761 55.1124 39.1585 54.9658 39.4546 54.6992L42.7712 51.7143V46.7706L41.1341 45.3673Z"
				fill="#41479B"
			/>
			<path
				d="M45.4999 49.1091L56.9602 39.2861V53.5025C56.9602 55.8986 55.0177 57.8407 52.6221 57.8407C51.5503 57.8407 50.5165 57.444 49.7198 56.7272L45.4999 52.9294V49.1091Z"
				fill="#FFE15A"
			/>
			<path
				d="M51.5452 54.6989L48.2286 51.7141V46.7704L54.2316 41.625V45.219V53.5026C54.2316 54.39 53.5095 55.1122 52.622 55.1122C52.2234 55.1122 51.8413 54.9655 51.5452 54.6989Z"
				fill="#FF4B55"
			/>
			<path
				d="M49.8657 45.3673V50.9867L52.5943 53.4504V45.1849V43.0286L54.2316 41.6252V45.2192V53.5029C54.2316 54.3903 53.5095 55.1124 52.622 55.1124C52.2237 55.1124 51.8413 54.9658 51.5452 54.6992L48.2286 51.7143V46.7706L49.8657 45.3673Z"
				fill="#41479B"
			/>
			<path
				d="M45.4999 53.4752C48.5139 53.4752 50.9572 49.81 50.9572 45.2887C50.9572 40.7675 48.5139 37.1023 45.4999 37.1023C42.4859 37.1023 40.0426 40.7675 40.0426 45.2887C40.0426 49.81 42.4859 53.4752 45.4999 53.4752Z"
				fill="#69C3FF"
			/>
			<path
				d="M40.5884 47.4721H50.4114L50.9573 45.2894L48.4702 45.704C47.9713 45.7872 47.4611 45.645 47.077 45.3158L44.2538 42.896C44.0016 42.6797 43.6801 42.5608 43.3479 42.5608C42.9788 42.5608 42.6246 42.7074 42.3636 42.9685L40.5884 44.7437V47.4721Z"
				fill="#009B64"
			/>
			<path
				d="M44.9822 44.9224L45.9195 44.3234L44.2535 42.8955C44.0013 42.6792 43.6797 42.5603 43.3475 42.5603C42.9784 42.5603 42.6242 42.7069 42.3633 42.968L40.588 44.7432V45.2891L43.3166 44.7432L43.799 44.9844C44.1772 45.1735 44.6264 45.1497 44.9822 44.9224Z"
				fill="#F5F5F5"
			/>
			<path
				d="M40.5884 47.4722L41.1341 50.7464L43.8627 53.4751H45.4999L47.6827 52.9295L49.8658 50.7464L50.4114 47.4722H40.5884Z"
				fill="#5AA0E6"
			/>
			<path
				d="M45.4999 54.0206C42.1898 54.0206 39.497 50.1035 39.497 45.2888C39.497 40.4742 42.1898 36.5571 45.4999 36.5571C48.8101 36.5571 51.5029 40.4742 51.5029 45.2888C51.5029 50.1035 48.8101 54.0206 45.4999 54.0206ZM45.4999 37.6489C42.792 37.6489 40.5884 41.0764 40.5884 45.2892C40.5884 49.502 42.7918 52.9295 45.4999 52.9295C48.2081 52.9295 50.4115 49.502 50.4115 45.2892C50.4115 41.0764 48.2079 37.6489 45.4999 37.6489Z"
				fill="#FFC88C"
			/>
			<path
				d="M41.65 53.426C41.1925 52.7943 40.5115 52.3835 39.7401 52.3835C38.9687 52.3835 38.2877 52.7943 37.8302 53.426C38.6104 53.6423 39.2488 54.2757 39.5512 55.1107C39.6729 55.447 39.7403 55.8159 39.7403 56.2038C39.7401 54.8537 40.5557 53.7293 41.65 53.426Z"
				fill="#E1E1E1"
			/>
			<path
				d="M40.1734 55.1476C40.1734 55.1476 40.1309 55.8752 39.7577 56.2163L39.742 56.2035C39.369 55.8624 39.3263 55.1348 39.3263 55.1348L40.1734 55.1476Z"
				fill="#E1E1E1"
			/>
			<path
				d="M52.3249 55.385H38.6749C38.526 55.385 38.4055 55.2643 38.4055 55.1156V55.1088C38.4055 54.9599 38.5262 54.8394 38.6749 54.8394H52.3249C52.4739 54.8394 52.5944 54.96 52.5944 55.1088V55.1156C52.5944 55.2643 52.4739 55.385 52.3249 55.385Z"
				fill="#966446"
			/>
			<path
				d="M48.242 56.2036H42.7579C42.1624 56.2036 41.6797 55.7209 41.6797 55.1255V55.0986C41.6797 54.5032 42.1624 54.0205 42.7579 54.0205H48.242C48.8374 54.0205 49.3202 54.5032 49.3202 55.0986V55.1255C49.32 55.7209 48.8373 56.2036 48.242 56.2036Z"
				fill="#BE915A"
			/>
			<path
				d="M40.5884 54.8394H38.6785C38.5279 54.8394 38.4056 54.9615 38.4056 55.1122C38.4056 55.2629 38.5277 55.385 38.6785 55.385H40.5884V54.8394Z"
				fill="#B9B9B9"
			/>
			<path
				d="M45.4999 56.2036V61.1898L43.3172 60.5695L44.4085 56.2036H45.4999Z"
				fill="#FF4B55"
			/>
			<path
				d="M44.9541 56.2036L43.8626 60.0238L41.6799 59.478L43.3172 56.2036H44.9541Z"
				fill="#41479B"
			/>
			<path
				d="M42.7713 56.2036L41.134 58.3865L42.7713 58.9322L43.8626 56.2036H42.7713Z"
				fill="#FFE15A"
			/>
			<path
				d="M45.4999 56.2036V61.1898L47.6827 60.5695L46.5914 56.2036H45.4999Z"
				fill="#FF4B55"
			/>
			<path
				d="M46.0457 56.2036L47.1372 60.0238L49.3199 59.478L47.6826 56.2036H46.0457Z"
				fill="#41479B"
			/>
			<path
				d="M48.2285 56.2036L49.8657 58.3865L48.2285 58.9322L47.1372 56.2036H48.2285Z"
				fill="#FFE15A"
			/>
			<path
				d="M34.4321 43.9862L33.6476 43.1956V39.2856H34.4321V43.9862Z"
				fill="#41479B"
			/>
			<path
				d="M57.3452 43.1956L56.5607 43.9862V39.2856H57.3452V43.1956Z"
				fill="#41479B"
			/>
		</svg>
	);
};
