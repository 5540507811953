import {
	Arrow,
	LandingVideo,
	Mouse,
	Overlay,
	ScrollDownWrapper,
	Subtitle,
	Title,
	Wrapper,
} from './LandingAtoms';

import video3 from '../videos/taxi3.webm';
import placeholder from '../images/placeholder.webp';
import styled from '@emotion/styled/macro';

import { t } from '../locales/translation';

export const ScrollDown: React.FC = () => {
	return (
		<ScrollDownWrapper href="#about" role="button" aria-label="scroll down">
			<Mouse>
				<span></span>
			</Mouse>
			<Arrow>
				<span></span>
			</Arrow>
		</ScrollDownWrapper>
	);
};

const Highlight = styled.span`
	color: #fdcf11;
`;

const Placeholder = styled.img`
	width: 100%;
	height: 100%;
`;

export const Landing: React.FC = () => {
	return (
		<Wrapper id="landing">
			<LandingVideo src={video3} autoPlay muted loop>
				<Placeholder src={placeholder} alt="" />
			</LandingVideo>
			<Overlay>
				<Title>{t('TAXI ECUADOR')}</Title>
				<Subtitle>
					{t('BEST')} <Highlight>{t('TAXI')}</Highlight>{' '}
					{t('EXPERIENCE')}
				</Subtitle>
				<ScrollDown />
			</Overlay>
		</Wrapper>
	);
};
