export const Success: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<svg
			viewBox="0 0 91 91"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0)">
				<path d="M91 45.4999L80.6984 34.0624L82.3113 18.7505L67.2477 15.5615L59.5638 2.21313L45.5001 8.49839L31.4364 2.21313L23.7524 15.5615L8.68884 18.7505L10.3016 34.0624L0 45.4999L10.3014 56.9373L8.68867 72.2492L23.7523 75.4383L31.4362 88.7866L45.4999 82.5012L59.5636 88.7866L67.2476 75.4383L82.3112 72.2492L80.6982 56.9373L91 45.4999ZM64.5747 35.5372L41.7355 61.1253L25.8647 45.2545L29.6396 41.4796L41.515 53.355L60.592 31.9823L64.5747 35.5372Z" />
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="91" height="91" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
