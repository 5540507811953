export const Communication: React.FC<{ className?: string }> = ({
	className,
}) => {
	return (
		<svg
			viewBox="0 0 91 91"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path d="M45.5 22.3945C44.519 22.3945 43.7227 23.1909 43.7227 24.1719C43.7227 25.1529 44.519 25.9492 45.5 25.9492C46.481 25.9492 47.2773 25.1529 47.2773 24.1719C47.2773 23.1909 46.481 22.3945 45.5 22.3945Z" />
			<path d="M15.9961 54.3867C16.9771 54.3867 17.7734 53.5904 17.7734 52.6094C17.7734 51.6284 16.9771 50.832 15.9961 50.832C15.0151 50.832 14.2188 51.6284 14.2188 52.6094C14.2188 53.5904 15.0151 54.3867 15.9961 54.3867Z" />
			<path d="M59.7188 4.62109C43.7178 4.62109 30.5849 15.6531 29.5692 29.549C14.3305 30.3134 0 41.3017 0 56.1641C0 62.375 2.45773 68.393 6.93164 73.1613C7.81684 76.8104 6.7421 80.6782 4.07539 83.3449C3.56718 83.8531 3.41514 84.6175 3.69007 85.282C3.965 85.9457 4.61276 86.3789 5.33203 86.3789C10.4009 86.3789 15.2859 84.3912 18.9155 80.8886C22.4515 82.0938 27.0649 82.8242 31.2812 82.8242C47.2801 82.8242 60.4116 71.795 61.4301 57.9018C65.1515 57.7366 69.022 57.0493 72.0845 56.0058C75.7141 59.5084 80.5991 61.4961 85.668 61.4961C86.3872 61.4961 87.035 61.0629 87.3099 60.3991C87.5849 59.7347 87.4328 58.9703 86.9246 58.4621C84.2579 55.7954 83.1832 51.9276 84.0684 48.2785C88.5423 43.5102 91 37.4922 91 31.2812C91 15.8385 75.5656 4.62109 59.7188 4.62109ZM31.2812 79.2695C27.0864 79.2695 22.3064 78.4461 19.1044 77.1721C18.4184 76.8986 17.6346 77.0784 17.1354 77.6234C14.9894 79.9694 12.1991 81.6051 9.15887 82.357C10.8342 79.1473 11.2618 75.3635 10.2426 71.7783C10.1621 71.4957 10.0128 71.2375 9.80802 71.0271C5.77567 66.8739 3.55469 61.5954 3.55469 56.1641C3.55469 43.6393 16.2516 33.0586 31.2812 33.0586C45.4736 33.0586 57.9414 42.9277 57.9414 56.1641C57.9414 68.9047 45.9818 79.2695 31.2812 79.2695V79.2695ZM81.192 46.1443C80.9872 46.3553 80.8379 46.6136 80.7574 46.8955C79.7382 50.4807 80.1658 54.2645 81.8411 57.4742C78.8009 56.723 76.0106 55.0865 73.8646 52.7406C73.3654 52.1963 72.5816 52.0158 71.8956 52.2893C69.1206 53.3932 65.1619 54.1576 61.4246 54.3423C60.9053 47.621 57.5526 41.3038 51.8207 36.6133H75.0039C75.9856 36.6133 76.7812 35.8176 76.7812 34.8359C76.7812 33.8542 75.9856 33.0586 75.0039 33.0586H46.3602C42.3258 31.0049 37.7956 29.8073 33.1329 29.556C34.1542 17.6186 45.6881 8.17578 59.7188 8.17578C74.7484 8.17578 87.4453 18.7565 87.4453 31.2812C87.4453 36.7126 85.2243 41.9911 81.192 46.1443V46.1443Z" />
			<path d="M45.5 50.832H23.1055C22.1238 50.832 21.3281 51.6277 21.3281 52.6094C21.3281 53.5911 22.1238 54.3867 23.1055 54.3867H45.5C46.4817 54.3867 47.2773 53.5911 47.2773 52.6094C47.2773 51.6277 46.4817 50.832 45.5 50.832Z" />
			<path d="M45.5 61.4961H15.9961C15.0144 61.4961 14.2188 62.2917 14.2188 63.2734C14.2188 64.2551 15.0144 65.0508 15.9961 65.0508H45.5C46.4817 65.0508 47.2773 64.2551 47.2773 63.2734C47.2773 62.2917 46.4817 61.4961 45.5 61.4961Z" />
			<path d="M75.0039 22.3945H52.6094C51.6277 22.3945 50.832 23.1902 50.832 24.1719C50.832 25.1536 51.6277 25.9492 52.6094 25.9492H75.0039C75.9856 25.9492 76.7812 25.1536 76.7812 24.1719C76.7812 23.1902 75.9856 22.3945 75.0039 22.3945Z" />
		</svg>
	);
};
