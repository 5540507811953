import styled from '@emotion/styled/macro';
import { Email } from '../svgs/Email';
import { Facebook } from '../svgs/Facebook';
import { Instagram } from '../svgs/Instagram';
import { Phone } from '../svgs/Phone';
import { Twitter } from '../svgs/Twitter';

export const FooterWrapper = styled.div`
	display: flex;
	justify-content: space-evenly;
	padding: 100px 50px;
	background-color: #1a1a1a;
	color: #fff;
	overflow: hidden;
	@media (max-width: 1132px) {
		flex-direction: column;
		gap: 120px;
		justify-content: center;
		align-items: center;
	}
`;

export const About = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	max-width: 400px;
	width: 100%; ;
`;
export const Title = styled.h1`
	margin: 0;
	font-size: 48px;
`;
export const Text = styled.p`
	margin: 0;
	font-size: 20px;
`;
export const Row = styled.div`
	display: flex;
	align-items: center;
`;
export const Contact = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 400px;
	width: 100%;
	gap: 32px;
`;
export const QuickLinks = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 400px;
	width: 100%;
`;

export const BaseIcon = styled.svg`
	width: 2em;
	height: 2em;
	margin-right: 16px;
	fill: #fff;
`;

export const BaseIconWithoutMargin = styled.svg`
	width: 2em;
	height: 2em;
	fill: #fff;
`;
export const PhoneNumber = styled.p`
	margin: 0;
	font-size: 20px;
`;

export const EmailAddress = styled.p`
	margin: 0;
	font-size: 20px;
`;

export const PhoneSVG = BaseIcon.withComponent(Phone);
export const EmailSVG = BaseIcon.withComponent(Email);
export const FacebookSVG = BaseIconWithoutMargin.withComponent(Facebook);
export const InstagramSVG = BaseIconWithoutMargin.withComponent(Instagram);
export const TwitterSVG = BaseIconWithoutMargin.withComponent(Twitter);

export const Box = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 16px;
	border-radius: 14px;
	border: 1px solid #fff;
	transition: 200ms;
	&:hover {
		transform: scale(1.1);
	}
`;

export const Socials = styled.div`
	display: flex;
	gap: 8px;
`;

export const QuickLinksTitle = styled(Title)`
	font-size: 40px;
`;

export const List = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 32px;
`;

export const Link = styled.a`
	font-size: 24px;
	font-weight: 600;
	display: flex;
	vertical-align: middle;
	margin-bottom: 16px;
	text-decoration: none;
	transition: 200ms;
	color: #fff;
	&::before {
		content: '•';
		margin-right: 10px;
		margin-left: 24px;
	}
	&:hover {
		transform: translateX(5%);
	}
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

export const Input = styled.input`
	flex-grow: 1;
	border-radius: 4px;
	background-color: #262527;
	outline: none;
	border: none;
	padding: 12px;
	color: #fff;
	font-family: 'Dosis', sans-serif;
	font-size: 24px;
	font-weight: 600;
`;

export const TextareaProp = Input.withComponent('textarea');
export const Textarea = styled(TextareaProp)`
	min-height: 120px;
`;

export const Button = styled.button`
	width: 150px;
	padding: 8px;
	font-family: 'Dosis', sans-serif;
	font-size: 24px;
	font-weight: 600;
	color: #fff;
	background-color: #2541b2;
	outline: none;
	border-radius: 9999px;
	border: none;
	margin-left: auto;
	transition: 200ms;
	cursor: pointer;
	&:hover {
		transform: scale(1.1);
	}
`;
