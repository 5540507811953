import styled from '@emotion/styled/macro';

export const PricingWrapper = styled.div`
	background: #fdcf11;
	min-height: 500px;
	padding: 50px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const Title = styled.h1`
	font-size: 64px;
	margin: 0;
	text-align: center;
	font-style: italic;
	color: #1a1a1a;
	@media (max-width: 412px) {
		font-size: 48px;
	}
`;

export const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	max-width: 190px;
	width: 100%;
	padding: 32px;
	background-color: #fff;
	border-radius: 7px;
	gap: 16px;
	box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
	@media (max-width: 500px) {
		max-width: 250px;
	}
`;

export const Button = styled.button`
	width: 150px;
	padding: 12px;
	border-radius: 9999px;
	background-color: #1a1a1a;
	outline: none;
	border: none;
	color: #fff;
	font-weight: 700;
	cursor: pointer;
	transition: 200ms;
	&:hover {
		transform: scale(1.2);
	}
`;

export const PricingContainer = styled.div`
	display: flex;
	gap: 16px;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 50px;

	${CardWrapper}:nth-of-type(1),${CardWrapper}:nth-of-type(5) {
		background-color: #1a1a1a;
		color: #fff;

		a > ${Button} {
			background: #fff;
			color: #1a1a1a;
		}
	}
`;

export const Location = styled.p`
	margin: 0;
	font-weight: 400;
	font-size: 1.17em;
	text-transform: capitalize;
`;
export const Price = styled.p`
	font-size: 48px;
	font-weight: bold;
	margin: 0;
`;
export const Description = styled.p`
	font-size: 18px;
`;

export const Flex = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
`;
