import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import {
	Button,
	Container,
	Heading,
	SuccessSVG,
	SuccessWrapper,
	Text,
} from './PaymentSuccessAtoms';
import { Link } from 'react-router-dom';
import { t } from './locales/translation';

export const PaymentSuccess: React.FC = () => {
	const { width, height } = useWindowSize();
	return (
		<>
			<Confetti
				width={width}
				height={height}
				tweenDuration={10000}
				numberOfPieces={100}
			></Confetti>
			<SuccessWrapper>
				<Container>
					<SuccessSVG />
					<Heading>{t('Thank you for your order')}</Heading>
					<Text>{t('You will receive and email shortly')}</Text>
					<Link to="/">
						<Button>{t('Continue')}</Button>
					</Link>
				</Container>
			</SuccessWrapper>
		</>
	);
};
