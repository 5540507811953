import styled from '@emotion/styled/macro';
import parallaxImage from '../images/street.webp';
import { Communication } from '../svgs/Communication';
import { Diamond } from '../svgs/Diamond';
import { Fast } from '../svgs/Fast';
import { Ideea } from '../svgs/Ideea';
import { Security } from '../svgs/Security';

export const FeaturesWrapper = styled.div`
	display: flex;
	justify-content: center;
	min-height: 500px;
	background-attachment: fixed;
	background-image: url(${parallaxImage});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	padding: 150px 100px;
	align-items: center;
	gap: 32px;
`;

export const FeatureContainer = styled.div<{
	language?: string;
}>`
	display: flex;
	flex-direction: column;
	padding: 24px;
	align-items: center;
	background-color: #fff;
	max-width: 200px;
	width: 100%;
	border-radius: 14px;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	text-align: center;
	cursor: default;
	user-select: none;
`;

export const LargeFeatureContainer = styled(FeatureContainer)`
	min-height: ${props => (props.language === 'en' ? '400px' : '400px')};
	max-width: ${props => props.language === 'es' && '240px'};
	transition: 0.5s;
	@media (max-width: 1583px) {
		margin-top: 32px !important;
	}

	&:hover {
		transform: translateY(40px);
	}
	&:nth-of-type(even) {
		margin-top: -200px;
	}
`;

export const Paragraph = styled.p`
	font-size: 18px;
	transition: 1s;
	overflow: hidden;
`;

export const SmallFeatureContainer = styled(FeatureContainer)`
	min-height: 254px;
	justify-content: center;
	max-width: 300px;
	@media (max-width: 320px) {
		max-width: 220px;
	}
	${Paragraph} {
		max-height: 0;
		opacity: 0;
	}
	&:hover ${Paragraph} {
		max-height: 200px;
		opacity: 1;
	}
`;

export const SphereWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(167, 220, 254, 0.6);
	padding: 16px;
	border-radius: 50%;
	width: fit-content;
`;
export const Title = styled.h1`
	color: #2541b2;
	text-transform: uppercase;
`;

export const BaseIcon = styled.svg`
	width: 4em;
	height: 4em;
	fill: #2541b2;
`;

export const FeaturesWrapperLarge = styled(FeaturesWrapper)`
	flex-wrap: wrap;
`;
export const FeaturesWrapperSmall = styled(FeaturesWrapper)`
	flex-direction: column;
	padding: 50px 0;
`;

export const IdeeaSVG = BaseIcon.withComponent(Ideea);
export const SecuritySVG = BaseIcon.withComponent(Security);
export const DiamondSVG = BaseIcon.withComponent(Diamond);
export const FastSVG = BaseIcon.withComponent(Fast);
export const CommunicationSVG = BaseIcon.withComponent(Communication);
