export const Facebook: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<svg
			className={className}
			viewBox="0 0 91 91"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0)">
				<path d="M60.6553 15.1098H68.9628V0.640792C67.5296 0.443625 62.6004 0 56.8598 0C44.882 0 36.6768 7.53404 36.6768 21.3812V34.125H23.459V50.3003H36.6768V91H52.8824V50.304H65.5655L67.5789 34.1288H52.8786V22.9851C52.8824 18.31 54.1412 15.1098 60.6553 15.1098V15.1098Z" />
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="91" height="91" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
