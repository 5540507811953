export const Usa: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<svg
			viewBox="0 0 91 91"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M84.1848 15.6897H6.81522C3.05117 15.6897 0 18.7409 0 22.5049V68.4953C0 72.2592 3.05117 75.3105 6.81522 75.3105H84.1848C87.9487 75.3105 91 72.2594 91 68.4953V22.5049C91 18.7409 87.9488 15.6897 84.1848 15.6897Z"
				fill="#F5F5F5"
			/>
			<path
				d="M0.374847 20.276H90.625C89.7015 17.6069 87.1677 15.6897 84.1848 15.6897H6.81523C3.83214 15.6897 1.29853 17.6069 0.374847 20.276Z"
				fill="#FF4B55"
			/>
			<path d="M91 34.0342H0V38.6203H91V34.0342Z" fill="#FF4B55" />
			<path d="M91 24.8616H0V29.4477H91V24.8616Z" fill="#FF4B55" />
			<path
				d="M0 46.224C0 47.0905 0.702584 47.793 1.56904 47.793H91V43.2068H0V46.224Z"
				fill="#FF4B55"
			/>
			<path d="M91 61.553H0V66.1392H91V61.553Z" fill="#FF4B55" />
			<path
				d="M90.6252 70.7241H0.374847C1.29853 73.3932 3.83231 75.3104 6.81523 75.3104H84.1848C87.1679 75.3104 89.7015 73.3932 90.6252 70.7241Z"
				fill="#FF4B55"
			/>
			<path d="M91 52.3801H0V56.9662H91V52.3801Z" fill="#FF4B55" />
			<path
				d="M1.56904 47.7933H40.7932C41.6597 47.7933 42.3623 47.0907 42.3623 46.2243V17.2587C42.3623 16.3921 41.6597 15.6897 40.7932 15.6897H6.81523C3.05117 15.6899 0 18.741 0 22.5051V46.2243C0 47.0909 0.702584 47.7933 1.56904 47.7933Z"
				fill="#41479B"
			/>
			<path
				d="M4.40584 19.2909L4.75314 20.3324L5.851 20.3407C5.99319 20.342 6.05202 20.5233 5.93774 20.6077L5.05457 21.2598L5.38587 22.3065C5.42888 22.4419 5.27443 22.5542 5.15873 22.4714L4.26561 21.8332L3.3725 22.4716C3.25679 22.5544 3.1027 22.4421 3.14535 22.3066L3.47665 21.26L2.59349 20.6079C2.47921 20.5233 2.53804 20.342 2.68022 20.3409L3.77809 20.3326L4.12538 19.291C4.17017 19.1559 4.3607 19.156 4.40584 19.2909Z"
				fill="#F5F5F5"
			/>
			<path
				d="M4.40584 24.739L4.75314 25.7805L5.851 25.7888C5.99319 25.7901 6.05202 25.9712 5.93774 26.0558L5.05457 26.7081L5.38587 27.7548C5.42888 27.8902 5.27443 28.0025 5.15873 27.9197L4.26561 27.2815L3.3725 27.9199C3.25679 28.0025 3.1027 27.8904 3.14535 27.7549L3.47665 26.7083L2.59349 26.056C2.47921 25.9714 2.53804 25.7903 2.68022 25.789L3.77809 25.7807L4.12538 24.7391C4.17017 24.6042 4.3607 24.6042 4.40584 24.739Z"
				fill="#F5F5F5"
			/>
			<path
				d="M4.40584 30.1873L4.75314 31.2289L5.851 31.2372C5.99319 31.2385 6.05202 31.4196 5.93774 31.5042L5.05457 32.1565L5.38587 33.2031C5.42888 33.3386 5.27443 33.4509 5.15873 33.3681L4.26561 32.7298L3.3725 33.3682C3.25679 33.4509 3.1027 33.3389 3.14535 33.2033L3.47665 32.1566L2.59349 31.5043C2.47921 31.4197 2.53804 31.2386 2.68022 31.2374L3.77809 31.229L4.12538 30.1875C4.17017 30.0524 4.3607 30.0524 4.40584 30.1873Z"
				fill="#F5F5F5"
			/>
			<path
				d="M4.40584 35.6356L4.75314 36.6771L5.851 36.6855C5.99319 36.6867 6.05202 36.868 5.93774 36.9524L5.05457 37.6047L5.38587 38.6514C5.42888 38.7868 5.27443 38.8991 5.15873 38.8163L4.26561 38.1781L3.3725 38.8165C3.25679 38.8991 3.1027 38.787 3.14535 38.6516L3.47665 37.6049L2.59349 36.9526C2.47921 36.8683 2.53804 36.6869 2.68022 36.6856L3.77809 36.6773L4.12538 35.6358C4.17017 35.5007 4.3607 35.5007 4.40584 35.6356Z"
				fill="#F5F5F5"
			/>
			<path
				d="M4.40584 41.0839L4.75314 42.1255L5.851 42.1338C5.99319 42.1351 6.05202 42.3162 5.93774 42.4008L5.05457 43.053L5.38587 44.0997C5.42888 44.2352 5.27443 44.3475 5.15873 44.2647L4.26561 43.6264L3.3725 44.2648C3.25679 44.3475 3.1027 44.2353 3.14535 44.0999L3.47665 43.0532L2.59349 42.4009C2.47921 42.3163 2.53804 42.1352 2.68022 42.134L3.77809 42.1256L4.12538 41.0841C4.17017 40.9492 4.3607 40.9492 4.40584 41.0839Z"
				fill="#F5F5F5"
			/>
			<path
				d="M8.63467 21.9619L8.98196 23.0034L10.0798 23.0118C10.222 23.013 10.2808 23.1943 10.1666 23.2787L9.2834 23.931L9.61469 24.9777C9.6577 25.1131 9.50325 25.2254 9.38755 25.1426L8.49443 24.5042L7.60132 25.1426C7.48561 25.2253 7.33152 25.1131 7.37417 24.9777L7.70547 23.931L6.82231 23.2787C6.70803 23.1941 6.76686 23.013 6.90904 23.0118L8.00691 23.0034L8.3542 21.9619C8.39899 21.8272 8.5897 21.8272 8.63467 21.9619Z"
				fill="#F5F5F5"
			/>
			<path
				d="M8.63467 27.4103L8.98196 28.4518L10.0798 28.4601C10.222 28.4614 10.2808 28.6427 10.1666 28.7271L9.2834 29.3794L9.61469 30.4261C9.6577 30.5615 9.50325 30.6738 9.38755 30.591L8.49443 29.9526L7.60132 30.591C7.48561 30.6736 7.33152 30.5617 7.37417 30.4261L7.70547 29.3794L6.82231 28.7271C6.70803 28.6428 6.76686 28.4614 6.90904 28.4601L8.00691 28.4518L8.3542 27.4103C8.39899 27.2754 8.5897 27.2754 8.63467 27.4103Z"
				fill="#F5F5F5"
			/>
			<path
				d="M8.63467 32.8585L8.98196 33.9L10.0798 33.9084C10.222 33.9096 10.2808 34.0907 10.1666 34.1753L9.2834 34.8276L9.61469 35.8743C9.6577 36.0097 9.50325 36.1221 9.38755 36.0392L8.49443 35.4008L7.60132 36.0392C7.48561 36.1219 7.33152 36.0097 7.37417 35.8743L7.70547 34.8276L6.82231 34.1753C6.70803 34.0907 6.76686 33.9096 6.90904 33.9084L8.00691 33.9L8.3542 32.8585C8.39899 32.7236 8.5897 32.7236 8.63467 32.8585Z"
				fill="#F5F5F5"
			/>
			<path
				d="M8.63467 38.3069L8.98196 39.3484L10.0798 39.3567C10.222 39.358 10.2808 39.5391 10.1666 39.6237L9.2834 40.276L9.61469 41.3226C9.6577 41.4581 9.50325 41.5704 9.38755 41.4876L8.49443 40.8492L7.60132 41.4876C7.48561 41.5704 7.33152 41.4581 7.37417 41.3226L7.70547 40.276L6.82231 39.6237C6.70803 39.5391 6.76686 39.358 6.90904 39.3567L8.00691 39.3484L8.3542 38.3069C8.39899 38.1721 8.5897 38.1721 8.63467 38.3069Z"
				fill="#F5F5F5"
			/>
			<path
				d="M12.8635 19.2906L13.2108 20.3322L14.3087 20.3405C14.4509 20.3417 14.5097 20.523 14.3954 20.6075L13.5122 21.2596L13.8435 22.3062C13.8866 22.4417 13.7321 22.554 13.6164 22.4712L12.7233 21.8328L11.8302 22.4712C11.7145 22.554 11.5604 22.4417 11.603 22.3062L11.9343 21.2596L11.0512 20.6075C10.9369 20.5229 10.9957 20.3416 11.1379 20.3405L12.2358 20.3322L12.5831 19.2906C12.6278 19.1557 12.8185 19.1557 12.8635 19.2906Z"
				fill="#F5F5F5"
			/>
			<path
				d="M12.8635 24.739L13.2108 25.7805L14.3087 25.7889C14.4509 25.7901 14.5097 25.9712 14.3954 26.0558L13.5122 26.7081L13.8435 27.7548C13.8866 27.8902 13.7321 28.0025 13.6164 27.9197L12.7233 27.2813L11.8302 27.9197C11.7145 28.0024 11.5604 27.8902 11.603 27.7548L11.9343 26.7081L11.0512 26.0558C10.9369 25.9712 10.9957 25.7901 11.1379 25.7889L12.2358 25.7805L12.5831 24.739C12.6278 24.6043 12.8185 24.6043 12.8635 24.739Z"
				fill="#F5F5F5"
			/>
			<path
				d="M12.8635 30.1874L13.2108 31.2289L14.3087 31.2372C14.4509 31.2385 14.5097 31.4196 14.3954 31.5042L13.5122 32.1565L13.8435 33.2032C13.8866 33.3386 13.7321 33.4509 13.6164 33.3681L12.7233 32.7297L11.8302 33.3681C11.7145 33.4507 11.5604 33.3388 11.603 33.2032L11.9343 32.1565L11.0512 31.5042C10.9369 31.4196 10.9957 31.2385 11.1379 31.2372L12.2358 31.2289L12.5831 30.1874C12.6278 30.0525 12.8185 30.0525 12.8635 30.1874Z"
				fill="#F5F5F5"
			/>
			<path
				d="M12.8635 35.6356L13.2108 36.6771L14.3087 36.6855C14.4509 36.6867 14.5097 36.868 14.3954 36.9524L13.5122 37.6047L13.8435 38.6514C13.8866 38.7868 13.7321 38.8992 13.6164 38.8163L12.7233 38.1779L11.8302 38.8163C11.7145 38.899 11.5604 38.7868 11.603 38.6514L11.9343 37.6047L11.0512 36.9524C10.9369 36.8682 10.9957 36.6867 11.1379 36.6855L12.2358 36.6771L12.5831 35.6356C12.6278 35.5007 12.8185 35.5007 12.8635 35.6356Z"
				fill="#F5F5F5"
			/>
			<path
				d="M12.8635 41.084L13.2108 42.1255L14.3087 42.1338C14.4509 42.1351 14.5097 42.3162 14.3954 42.4008L13.5122 43.0531L13.8435 44.0997C13.8866 44.2352 13.7321 44.3475 13.6164 44.2647L12.7233 43.6263L11.8302 44.2647C11.7145 44.3473 11.5604 44.2352 11.603 44.0997L11.9343 43.0531L11.0512 42.4008C10.9369 42.3162 10.9957 42.1351 11.1379 42.1338L12.2358 42.1255L12.5831 41.084C12.6278 40.9492 12.8185 40.9492 12.8635 41.084Z"
				fill="#F5F5F5"
			/>
			<path
				d="M17.0925 21.9619L17.4398 23.0034L18.5377 23.0118C18.6799 23.013 18.7387 23.1943 18.6244 23.2787L17.7413 23.931L18.0725 24.9777C18.1156 25.1131 17.9611 25.2254 17.8454 25.1426L16.9523 24.5042L16.0592 25.1426C15.9435 25.2253 15.7894 25.1131 15.832 24.9777L16.1633 23.931L15.2802 23.2787C15.1659 23.1941 15.2247 23.013 15.3669 23.0118L16.4648 23.0034L16.8121 21.9619C16.8567 21.8272 17.0476 21.8272 17.0925 21.9619Z"
				fill="#F5F5F5"
			/>
			<path
				d="M17.0925 27.4103L17.4398 28.4518L18.5377 28.4601C18.6799 28.4614 18.7387 28.6427 18.6244 28.7271L17.7413 29.3794L18.0725 30.4261C18.1156 30.5615 17.9611 30.6738 17.8454 30.591L16.9523 29.9526L16.0592 30.591C15.9435 30.6736 15.7894 30.5617 15.832 30.4261L16.1633 29.3794L15.2802 28.7271C15.1659 28.6428 15.2247 28.4614 15.3669 28.4601L16.4648 28.4518L16.8121 27.4103C16.8567 27.2754 17.0476 27.2754 17.0925 27.4103Z"
				fill="#F5F5F5"
			/>
			<path
				d="M17.0925 32.8585L17.4398 33.9L18.5377 33.9084C18.6799 33.9096 18.7387 34.0907 18.6244 34.1753L17.7413 34.8276L18.0725 35.8743C18.1156 36.0097 17.9611 36.1221 17.8454 36.0392L16.9523 35.4008L16.0592 36.0392C15.9435 36.1219 15.7894 36.0097 15.832 35.8743L16.1633 34.8276L15.2802 34.1753C15.1659 34.0907 15.2247 33.9096 15.3669 33.9084L16.4648 33.9L16.8121 32.8585C16.8567 32.7236 17.0476 32.7236 17.0925 32.8585Z"
				fill="#F5F5F5"
			/>
			<path
				d="M17.0925 38.3069L17.4398 39.3484L18.5377 39.3567C18.6799 39.358 18.7387 39.5391 18.6244 39.6237L17.7413 40.276L18.0725 41.3226C18.1156 41.4581 17.9611 41.5704 17.8454 41.4876L16.9523 40.8492L16.0592 41.4876C15.9435 41.5704 15.7894 41.4581 15.832 41.3226L16.1633 40.276L15.2802 39.6237C15.1659 39.5391 15.2247 39.358 15.3669 39.3567L16.4648 39.3484L16.8121 38.3069C16.8567 38.1721 17.0476 38.1721 17.0925 38.3069Z"
				fill="#F5F5F5"
			/>
			<path
				d="M21.3214 19.2909L21.6687 20.3324L22.7665 20.3407C22.9087 20.342 22.9675 20.5233 22.8533 20.6077L21.9701 21.2598L22.3014 22.3065C22.3444 22.4419 22.19 22.5542 22.0743 22.4714L21.181 21.8332L20.2878 22.4716C20.1721 22.5544 20.018 22.4421 20.0607 22.3066L20.392 21.26L19.5088 20.6079C19.3945 20.5233 19.4534 20.342 19.5956 20.3409L20.6934 20.3326L21.0407 19.291C21.0857 19.1559 21.2764 19.156 21.3214 19.2909Z"
				fill="#F5F5F5"
			/>
			<path
				d="M21.3214 24.739L21.6687 25.7805L22.7665 25.7889C22.9087 25.7901 22.9675 25.9712 22.8533 26.0558L21.9701 26.7081L22.3014 27.7548C22.3444 27.8902 22.19 28.0025 22.0743 27.9197L21.181 27.2813L20.2878 27.9197C20.1721 28.0024 20.018 27.8902 20.0607 27.7548L20.392 26.7081L19.5088 26.0558C19.3945 25.9712 19.4534 25.7901 19.5956 25.7889L20.6934 25.7805L21.0407 24.739C21.0857 24.6043 21.2764 24.6043 21.3214 24.739Z"
				fill="#F5F5F5"
			/>
			<path
				d="M21.3214 30.1874L21.6687 31.2289L22.7665 31.2373C22.9087 31.2385 22.9675 31.4196 22.8533 31.5042L21.9701 32.1565L22.3014 33.2032C22.3444 33.3386 22.19 33.4509 22.0743 33.3681L21.181 32.7295L20.2878 33.3679C20.1721 33.4506 20.018 33.3384 20.0607 33.203L20.392 32.1563L19.5088 31.504C19.3945 31.4194 19.4534 31.2383 19.5956 31.2371L20.6934 31.2287L21.0407 30.1872C21.0857 30.0525 21.2764 30.0525 21.3214 30.1874Z"
				fill="#F5F5F5"
			/>
			<path
				d="M21.3214 35.6356L21.6687 36.6771L22.7665 36.6855C22.9087 36.6867 22.9675 36.868 22.8533 36.9524L21.9701 37.6047L22.3014 38.6514C22.3444 38.7868 22.19 38.8992 22.0743 38.8163L21.181 38.1779L20.2878 38.8163C20.1721 38.899 20.018 38.7868 20.0607 38.6514L20.392 37.6047L19.5088 36.9524C19.3945 36.8682 19.4534 36.6867 19.5956 36.6855L20.6934 36.6771L21.0407 35.6356C21.0857 35.5007 21.2764 35.5007 21.3214 35.6356Z"
				fill="#F5F5F5"
			/>
			<path
				d="M21.3214 41.084L21.6687 42.1255L22.7665 42.1338C22.9087 42.1351 22.9675 42.3162 22.8533 42.4008L21.9701 43.0531L22.3014 44.0997C22.3444 44.2352 22.19 44.3475 22.0743 44.2647L21.181 43.6263L20.2878 44.2647C20.1721 44.3473 20.018 44.2352 20.0607 44.0997L20.392 43.0531L19.5088 42.4008C19.3945 42.3162 19.4534 42.1351 19.5956 42.1338L20.6934 42.1255L21.0407 41.084C21.0857 40.9492 21.2764 40.9492 21.3214 41.084Z"
				fill="#F5F5F5"
			/>
			<path
				d="M25.5502 21.9619L25.8975 23.0034L26.9953 23.0118C27.1375 23.013 27.1964 23.1943 27.0821 23.2787L26.1989 23.931L26.5302 24.9777C26.5732 25.1131 26.4188 25.2254 26.3031 25.1426L25.41 24.5042L24.5168 25.1426C24.4011 25.2253 24.247 25.1131 24.2897 24.9777L24.621 23.931L23.7378 23.2787C23.6236 23.1941 23.6824 23.013 23.8246 23.0118L24.9224 23.0034L25.2697 21.9619C25.3147 21.8272 25.5052 21.8272 25.5502 21.9619Z"
				fill="#F5F5F5"
			/>
			<path
				d="M25.5502 27.4103L25.8975 28.4518L26.9953 28.4601C27.1375 28.4614 27.1964 28.6427 27.0821 28.7271L26.1989 29.3794L26.5302 30.4261C26.5732 30.5615 26.4188 30.6738 26.3031 30.591L25.41 29.9526L24.5168 30.591C24.4011 30.6736 24.247 30.5617 24.2897 30.4261L24.621 29.3794L23.7378 28.7271C23.6236 28.6428 23.6824 28.4614 23.8246 28.4601L24.9224 28.4518L25.2697 27.4103C25.3147 27.2754 25.5052 27.2754 25.5502 27.4103Z"
				fill="#F5F5F5"
			/>
			<path
				d="M25.5502 32.8585L25.8975 33.9L26.9953 33.9084C27.1375 33.9096 27.1964 34.0907 27.0821 34.1753L26.1989 34.8276L26.5302 35.8743C26.5732 36.0097 26.4188 36.1221 26.3031 36.0392L25.41 35.4008L24.5168 36.0392C24.4011 36.1219 24.247 36.0097 24.2897 35.8743L24.621 34.8276L23.7378 34.1753C23.6236 34.0907 23.6824 33.9096 23.8246 33.9084L24.9224 33.9L25.2697 32.8585C25.3147 32.7236 25.5052 32.7236 25.5502 32.8585Z"
				fill="#F5F5F5"
			/>
			<path
				d="M25.5502 38.3069L25.8975 39.3484L26.9953 39.3567C27.1375 39.358 27.1964 39.5391 27.0821 39.6237L26.1989 40.276L26.5302 41.3226C26.5732 41.4581 26.4188 41.5704 26.3031 41.4876L25.41 40.8492L24.5168 41.4876C24.4011 41.5704 24.247 41.4581 24.2897 41.3226L24.621 40.276L23.7378 39.6237C23.6236 39.5391 23.6824 39.358 23.8246 39.3567L24.9224 39.3484L25.2697 38.3069C25.3147 38.1721 25.5052 38.1721 25.5502 38.3069Z"
				fill="#F5F5F5"
			/>
			<path
				d="M29.7792 19.2906L30.1265 20.3322L31.2243 20.3405C31.3665 20.3417 31.4254 20.523 31.3111 20.6075L30.4279 21.2596L30.7592 22.3062C30.8022 22.4417 30.6478 22.554 30.5321 22.4712L29.639 21.8328L28.7458 22.4712C28.6301 22.554 28.476 22.4417 28.5187 22.3062L28.85 21.2596L27.9668 20.6075C27.8526 20.5229 27.9114 20.3416 28.0536 20.3405L29.1514 20.3322L29.4987 19.2906C29.5433 19.1557 29.7342 19.1557 29.7792 19.2906Z"
				fill="#F5F5F5"
			/>
			<path
				d="M29.7792 24.739L30.1265 25.7805L31.2243 25.7889C31.3665 25.7901 31.4254 25.9712 31.3111 26.0558L30.4279 26.7081L30.7592 27.7548C30.8022 27.8902 30.6478 28.0025 30.5321 27.9197L29.639 27.2813L28.7458 27.9197C28.6301 28.0024 28.476 27.8902 28.5187 27.7548L28.85 26.7081L27.9668 26.0558C27.8526 25.9712 27.9114 25.7901 28.0536 25.7889L29.1514 25.7805L29.4987 24.739C29.5433 24.6043 29.7342 24.6043 29.7792 24.739Z"
				fill="#F5F5F5"
			/>
			<path
				d="M29.7792 30.1874L30.1265 31.2289L31.2243 31.2372C31.3665 31.2385 31.4254 31.4196 31.3111 31.5042L30.4279 32.1565L30.7592 33.2032C30.8022 33.3386 30.6478 33.4509 30.5321 33.3681L29.639 32.7297L28.7458 33.3681C28.6301 33.4507 28.476 33.3388 28.5187 33.2032L28.85 32.1565L27.9668 31.5042C27.8526 31.4196 27.9114 31.2385 28.0536 31.2372L29.1514 31.2289L29.4987 30.1874C29.5433 30.0525 29.7342 30.0525 29.7792 30.1874Z"
				fill="#F5F5F5"
			/>
			<path
				d="M29.7792 35.6356L30.1265 36.6771L31.2243 36.6855C31.3665 36.6867 31.4254 36.868 31.3111 36.9524L30.4279 37.6047L30.7592 38.6514C30.8022 38.7868 30.6478 38.8992 30.5321 38.8163L29.639 38.1779L28.7458 38.8163C28.6301 38.899 28.476 38.7868 28.5187 38.6514L28.85 37.6047L27.9668 36.9524C27.8526 36.8682 27.9114 36.6867 28.0536 36.6855L29.1514 36.6771L29.4987 35.6356C29.5433 35.5007 29.7342 35.5007 29.7792 35.6356Z"
				fill="#F5F5F5"
			/>
			<path
				d="M29.7792 41.084L30.1265 42.1255L31.2243 42.1338C31.3665 42.1351 31.4254 42.3162 31.3111 42.4008L30.4279 43.0531L30.7592 44.0997C30.8022 44.2352 30.6478 44.3475 30.5321 44.2647L29.639 43.6263L28.7458 44.2647C28.6301 44.3473 28.476 44.2352 28.5187 44.0997L28.85 43.0531L27.9668 42.4008C27.8526 42.3162 27.9114 42.1351 28.0536 42.1338L29.1514 42.1255L29.4987 41.084C29.5433 40.9492 29.7342 40.9492 29.7792 41.084Z"
				fill="#F5F5F5"
			/>
			<path
				d="M34.008 21.9619L34.3553 23.0034L35.4532 23.0118C35.5954 23.013 35.6542 23.1943 35.5399 23.2787L34.6568 23.931L34.9881 24.9777C35.0311 25.1131 34.8766 25.2254 34.7609 25.1426L33.8678 24.5042L32.9747 25.1426C32.859 25.2253 32.7049 25.1131 32.7476 24.9777L33.0789 23.931L32.1957 23.2787C32.0814 23.1941 32.1402 23.013 32.2824 23.0118L33.3803 23.0034L33.7276 21.9619C33.7724 21.8272 33.9631 21.8272 34.008 21.9619Z"
				fill="#F5F5F5"
			/>
			<path
				d="M34.008 27.4103L34.3553 28.4518L35.4532 28.4601C35.5954 28.4614 35.6542 28.6427 35.5399 28.7271L34.6568 29.3794L34.9881 30.4261C35.0311 30.5615 34.8766 30.6738 34.7609 30.591L33.8678 29.9526L32.9747 30.591C32.859 30.6736 32.7049 30.5617 32.7476 30.4261L33.0789 29.3794L32.1957 28.7271C32.0814 28.6428 32.1402 28.4614 32.2824 28.4601L33.3803 28.4518L33.7276 27.4103C33.7724 27.2754 33.9631 27.2754 34.008 27.4103Z"
				fill="#F5F5F5"
			/>
			<path
				d="M34.008 32.8585L34.3553 33.9L35.4532 33.9084C35.5954 33.9096 35.6542 34.0907 35.5399 34.1753L34.6568 34.8276L34.9881 35.8743C35.0311 36.0097 34.8766 36.1221 34.7609 36.0392L33.8678 35.4008L32.9747 36.0392C32.859 36.1219 32.7049 36.0097 32.7476 35.8743L33.0789 34.8276L32.1957 34.1753C32.0814 34.0907 32.1402 33.9096 32.2824 33.9084L33.3803 33.9L33.7276 32.8585C33.7724 32.7236 33.9631 32.7236 34.008 32.8585Z"
				fill="#F5F5F5"
			/>
			<path
				d="M34.008 38.3069L34.3553 39.3484L35.4532 39.3567C35.5954 39.358 35.6542 39.5391 35.5399 39.6237L34.6568 40.276L34.9881 41.3226C35.0311 41.4581 34.8766 41.5704 34.7609 41.4876L33.8678 40.8492L32.9747 41.4876C32.859 41.5704 32.7049 41.4581 32.7476 41.3226L33.0789 40.276L32.1957 39.6237C32.0814 39.5391 32.1402 39.358 32.2824 39.3567L33.3803 39.3484L33.7276 38.3069C33.7724 38.1721 33.9631 38.1721 34.008 38.3069Z"
				fill="#F5F5F5"
			/>
			<path
				d="M38.2371 19.2906L38.5843 20.3322L39.6822 20.3405C39.8244 20.3417 39.8832 20.523 39.7689 20.6075L38.8858 21.2596L39.2171 22.3062C39.2601 22.4417 39.1056 22.554 38.9899 22.4712L38.0968 21.8328L37.2037 22.4712C37.088 22.554 36.9339 22.4417 36.9766 22.3062L37.3079 21.2596L36.4247 20.6075C36.3104 20.5229 36.3692 20.3416 36.5114 20.3405L37.6093 20.3322L37.9566 19.2906C38.0014 19.1557 38.1919 19.1557 38.2371 19.2906Z"
				fill="#F5F5F5"
			/>
			<path
				d="M38.2371 24.739L38.5843 25.7805L39.6822 25.7889C39.8244 25.7901 39.8832 25.9712 39.7689 26.0558L38.8858 26.7081L39.2171 27.7548C39.2601 27.8902 39.1056 28.0025 38.9899 27.9197L38.0968 27.2813L37.2037 27.9197C37.088 28.0024 36.9339 27.8902 36.9766 27.7548L37.3079 26.7081L36.4247 26.0558C36.3104 25.9712 36.3692 25.7901 36.5114 25.7889L37.6093 25.7805L37.9566 24.739C38.0014 24.6043 38.1919 24.6043 38.2371 24.739Z"
				fill="#F5F5F5"
			/>
			<path
				d="M38.2371 30.1874L38.5843 31.2289L39.6822 31.2372C39.8244 31.2385 39.8832 31.4196 39.7689 31.5042L38.8858 32.1565L39.2171 33.2032C39.2601 33.3386 39.1056 33.4509 38.9899 33.3681L38.0968 32.7297L37.2037 33.3681C37.088 33.4507 36.9339 33.3388 36.9766 33.2032L37.3079 32.1565L36.4247 31.5042C36.3104 31.4196 36.3692 31.2385 36.5114 31.2372L37.6093 31.2289L37.9566 30.1874C38.0014 30.0525 38.1919 30.0525 38.2371 30.1874Z"
				fill="#F5F5F5"
			/>
			<path
				d="M38.2371 35.6356L38.5843 36.6771L39.6822 36.6855C39.8244 36.6867 39.8832 36.868 39.7689 36.9524L38.8858 37.6047L39.2171 38.6514C39.2601 38.7868 39.1056 38.8992 38.9899 38.8163L38.0968 38.1779L37.2037 38.8163C37.088 38.899 36.9339 38.7868 36.9766 38.6514L37.3079 37.6047L36.4247 36.9524C36.3104 36.8682 36.3692 36.6867 36.5114 36.6855L37.6093 36.6771L37.9566 35.6356C38.0014 35.5007 38.1919 35.5007 38.2371 35.6356Z"
				fill="#F5F5F5"
			/>
			<path
				d="M38.2371 41.084L38.5843 42.1255L39.6822 42.1338C39.8244 42.1351 39.8832 42.3162 39.7689 42.4008L38.8858 43.0531L39.2171 44.0997C39.2601 44.2352 39.1056 44.3475 38.9899 44.2647L38.0968 43.6263L37.2037 44.2647C37.088 44.3473 36.9339 44.2352 36.9766 44.0997L37.3079 43.0531L36.4247 42.4008C36.3104 42.3162 36.3692 42.1351 36.5114 42.1338L37.6093 42.1255L37.9566 41.084C38.0014 40.9492 38.1919 40.9492 38.2371 41.084Z"
				fill="#F5F5F5"
			/>
		</svg>
	);
};
