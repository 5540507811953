export type DestinationType =
	| 'AMBATO'
	| 'ANCONCITO'
	| 'AZOGUEZ'
	| 'AYANGUE'
	| 'BABAHOYO'
	| 'BAÑOS'
	| 'BALLENITA'
	| 'BAHIA'
	| 'BUCAY'
	| 'CANOA'
	| 'CHONGON'
	| 'CUENCA'
	| 'DAULE'
	| 'ESMERALDS'
	| 'GUARANDA'
	| 'HUAQUILLAS'
	| 'IBARRA'
	| 'JIPIJAPA'
	| 'LIBERTAD'
	| 'MANCORA'
	| 'MANGLARALTO'
	| 'MANTA'
	| 'MILAGRO'
	| 'MONTAÑITA'
	| 'OLON'
	| 'PEDRO CARBO'
	| 'PLAYA'
	| 'POSORJA'
	| 'PTO. LOPEZ'
	| 'PTO. CAYO'
	| 'PUNTA CARNERO'
	| 'QUITO'
	| 'SALITRE'
	| 'SALINAS'
	| 'SAMBORONDON'
	| 'TRONCAL'
	| 'YAGUACHI';

export const Destination: DestinationType[] = [
	'AMBATO',
	'ANCONCITO',
	'AZOGUEZ',
	'AYANGUE',
	'BABAHOYO',
	'BAÑOS',
	'BALLENITA',
	'BAHIA',
	'BUCAY',
	'CANOA',
	'CHONGON',
	'CUENCA',
	'DAULE',
	'ESMERALDS',
	'GUARANDA',
	'HUAQUILLAS',
	'IBARRA',
	'JIPIJAPA',
	'LIBERTAD',
	'MANCORA',
	'MANGLARALTO',
	'MANTA',
	'MILAGRO',
	'MONTAÑITA',
	'OLON',
	'PEDRO CARBO',
	'PLAYA',
	'POSORJA',
	'PTO. LOPEZ',
	'PTO. CAYO',
	'PUNTA CARNERO',
	'QUITO',
	'SALITRE',
	'SALINAS',
	'SAMBORONDON',
	'TRONCAL',
	'YAGUACHI',
];

export type DestinationInfo = {
	priceOneWay: number;
	priceTwoWays: number;
	waitTime?: number;
	kilometers?: number;
};

export const LocationMap = new Map<DestinationType, DestinationInfo>([
	[
		'AMBATO',
		{
			priceOneWay: 255,
			priceTwoWays: 220,
			waitTime: 2,
			kilometers: 295,
		},
	],
	[
		'ANCONCITO',
		{
			priceOneWay: 112.5,
			priceTwoWays: 110,
			waitTime: 2,
			kilometers: 120,
		},
	],
	[
		'AZOGUEZ',
		{
			priceOneWay: 162.5,
			priceTwoWays: 170,
			waitTime: 2,
			kilometers: 213,
		},
	],
	[
		'AYANGUE',
		{
			priceOneWay: 137.5,
			priceTwoWays: 130,
			waitTime: 3,
			kilometers: 185,
		},
	],
	[
		'BABAHOYO',
		{
			priceOneWay: 75,
			priceTwoWays: 75,
			waitTime: 1,
			kilometers: 85,
		},
	],
	[
		'BAÑOS',
		{
			priceOneWay: 212.5,
			priceTwoWays: 210,
			waitTime: 2,
			kilometers: 288,
		},
	],
	[
		'BALLENITA',
		{
			priceOneWay: 112.5,
			priceTwoWays: 110,
			waitTime: 2,
		},
	],
	[
		'BAHIA',
		{
			priceOneWay: 187.5,
			priceTwoWays: 200,
			waitTime: 2,
			kilometers: 280,
		},
	],
	[
		'BUCAY',
		{
			priceOneWay: 100,
			priceTwoWays: 100,
			waitTime: 2,
			kilometers: 100,
		},
	],
	[
		'CANOA',
		{
			priceOneWay: 250,
			priceTwoWays: 245,
			waitTime: 2,
			kilometers: 325,
		},
	],
	[
		'CHONGON',
		{
			priceOneWay: 31.25,
			priceTwoWays: 40,
			waitTime: 2,
		},
	],
	[
		'CUENCA',
		{
			priceOneWay: 175,
			priceTwoWays: 170,
			waitTime: 2,
			kilometers: 260,
		},
	],
	[
		'DAULE',
		{
			priceOneWay: 58.75,
			priceTwoWays: 60,
			waitTime: 1,
		},
	],
	[
		'ESMERALDS',
		{
			priceOneWay: 325,
			priceTwoWays: 290,
			waitTime: 2,
			kilometers: 480,
		},
	],
	[
		'GUARANDA',
		{
			priceOneWay: 187.5,
			priceTwoWays: 175,
			waitTime: 2,
			kilometers: 204,
		},
	],
	[
		'HUAQUILLAS',
		{
			priceOneWay: 175,
			priceTwoWays: 170,
			waitTime: 2,
			kilometers: 253,
		},
	],
	[
		'IBARRA',
		{
			priceOneWay: 375,
			priceTwoWays: 330,
			waitTime: 1,
			kilometers: 535,
		},
	],
	[
		'JIPIJAPA',
		{
			priceOneWay: 112.5,
			priceTwoWays: 110,
			waitTime: 2,
			kilometers: 163,
		},
	],
	[
		'LIBERTAD',
		{
			priceOneWay: 106.25,
			priceTwoWays: 100,
			waitTime: 2,
			kilometers: 145,
		},
	],
	[
		'MANCORA',
		{
			priceOneWay: 362.5,
			priceTwoWays: 320,
			waitTime: 1,
			kilometers: 380,
		},
	],
	[
		'MANGLARALTO',
		{
			priceOneWay: 162.5,
			priceTwoWays: 145,
			waitTime: 2,
			kilometers: 209,
		},
	],
	[
		'MANTA',
		{
			priceOneWay: 156.25,
			priceTwoWays: 140,
			waitTime: 2,
			kilometers: 196,
		},
	],
	[
		'MILAGRO',
		{
			priceOneWay: 50,
			priceTwoWays: 55,
			waitTime: 1,
			kilometers: 45,
		},
	],
	[
		'MONTAÑITA',
		{
			priceOneWay: 162.5,
			priceTwoWays: 145,
			waitTime: 2,
			kilometers: 210,
		},
	],
	[
		'OLON',
		{
			priceOneWay: 162.5,
			priceTwoWays: 145,
			waitTime: 2,
			kilometers: 215,
		},
	],
	[
		'PEDRO CARBO',
		{
			priceOneWay: 87.5,
			priceTwoWays: 85,
			waitTime: 2,
			kilometers: 86,
		},
	],
	[
		'PLAYA',
		{
			priceOneWay: 81.25,
			priceTwoWays: 75,
			waitTime: 2,
			kilometers: 100,
		},
	],
	[
		'POSORJA',
		{
			priceOneWay: 100,
			priceTwoWays: 95,
			waitTime: 2,
			kilometers: 117,
		},
	],
	[
		'PTO. LOPEZ',
		{
			priceOneWay: 181.25,
			priceTwoWays: 160,
			waitTime: 2,
			kilometers: 230,
		},
	],
	[
		'PTO. CAYO',
		{
			priceOneWay: 181.25,
			priceTwoWays: 160,
			kilometers: 175,
		},
	],
	[
		'PUNTA CARNERO',
		{
			priceOneWay: 118.75,
			priceTwoWays: 115,
			waitTime: 2,
			kilometers: 160,
		},
	],
	[
		'QUITO',
		{
			priceOneWay: 318.75,
			priceTwoWays: 270,
			waitTime: 2,
			kilometers: 425,
		},
	],
	[
		'SALITRE',
		{
			priceOneWay: 68.75,
			priceTwoWays: 65,
			waitTime: 1,
			kilometers: 70,
		},
	],
	[
		'SALINAS',
		{
			priceOneWay: 112.5,
			priceTwoWays: 110,
			waitTime: 2,
			kilometers: 137.5,
		},
	],
	[
		'SAMBORONDON',
		{
			priceOneWay: 56.25,
			priceTwoWays: 55,
			waitTime: 1,
			kilometers: 20,
		},
	],
	[
		'TRONCAL',
		{
			priceOneWay: 75,
			priceTwoWays: 80,
			waitTime: 2,
			kilometers: 80,
		},
	],
	[
		'YAGUACHI',
		{
			priceOneWay: 37.5,
			priceTwoWays: 45,
			waitTime: 1,
			kilometers: 40,
		},
	],
]);
