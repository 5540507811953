import React from 'react';
import { Link } from 'react-router-dom';
import { t } from '../locales/translation';
import { LocationMap } from '../LocationMap';
import {
	Title,
	Button,
	CardWrapper,
	Description,
	Price,
	PricingContainer,
	PricingWrapper,
	Location,
	Flex,
} from './PricingAtoms';

const pricing = [
	{
		price: 20,
		destination: 'Hourly',
		description:
			'You can purchase additional chauffeur hours for an existing order, or purchase a chauffeur for driving around Guayaquil.',
	},
	{
		price: LocationMap.get('MILAGRO')?.priceOneWay!,
		destination: 'Milagro',
		description:
			'Milagro is a city located in Guayas, Ecuador and is the second largest city in the province of Guayas.',
	},
	{
		price: LocationMap.get('SALITRE')?.priceOneWay!,
		destination: 'Salitre',
		description:
			'Salitre Canton is a canton of Ecuador, located in the Guayas Province. Its capital is the town of Salitre.',
	},

	{
		price: LocationMap.get('CUENCA')?.priceOneWay!,
		destination: 'Cuenca',
		description:
			'Cuenca (Santa Ana de los Ríos de Cuenca) is a city in southern Ecuador’s Andes mountains.',
	},
	{
		personalized: 'View all',
		price: '???',
		description:
			'Want to visit another city? Choose from the finest cities in Ecuador.',
	},
];

const Card: React.FC<{
	price: number | string;
	description: string;
	destination?: string;
	personalized?: string;
}> = ({ price, description, destination, personalized }) => {
	return (
		<CardWrapper>
			<Location>{t(personalized ? personalized : destination!)}</Location>
			<Price>{'$' + price}</Price>
			<Flex>
				<Description>{t(description)}</Description>
				<Link
					to={
						destination === 'Hourly'
							? `/checkout/time`
							: destination
							? `/checkout?destination=${destination.toUpperCase()}`
							: '/checkout'
					}
				>
					<Button>{t('BUY NOW')}</Button>
				</Link>
			</Flex>
		</CardWrapper>
	);
};

export const Pricing: React.FC = () => {
	return (
		<PricingWrapper id="pricing">
			<Title>{t('Where do you want to go?')}</Title>
			<PricingContainer>
				{pricing.map((item, i) => (
					<Card
						key={i}
						personalized={item.personalized}
						price={item.price}
						description={item.description}
						destination={item.destination}
					></Card>
				))}
			</PricingContainer>
		</PricingWrapper>
	);
};
