import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { AuthProvider } from './AuthContext';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY!);

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Elements stripe={stripePromise}>
				<AuthProvider>
					<App />
				</AuthProvider>
			</Elements>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);
