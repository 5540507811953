export const Trash: React.FC<
	React.SVGProps<SVGSVGElement> & { className?: string }
> = ({ className, ...rest }) => {
	return (
		<svg
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			{...rest}
		>
			<path
				d="M36.4003 16.0262H11.5987C11.4491 16.0261 11.3008 16.0554 11.1625 16.1125C11.0241 16.1697 10.8984 16.2536 10.7926 16.3594C10.6867 16.4652 10.6027 16.5908 10.5454 16.7291C10.4881 16.8674 10.4587 17.0156 10.4587 17.1653V41.1111C10.4587 41.2608 10.4881 41.4091 10.5454 41.5475C10.6026 41.6859 10.6866 41.8117 10.7924 41.9176C10.8983 42.0236 11.024 42.1076 11.1623 42.165C11.3007 42.2224 11.449 42.2519 11.5987 42.252H36.4003C36.5501 42.2521 36.6985 42.2226 36.8369 42.1653C36.9753 42.1079 37.101 42.0239 37.2069 41.9179C37.3128 41.8119 37.3968 41.6861 37.454 41.5477C37.5112 41.4092 37.5405 41.2609 37.5403 41.1111V17.1648C37.5405 17.0151 37.5111 16.8669 37.4538 16.7286C37.3965 16.5903 37.3125 16.4647 37.2066 16.3589C37.1007 16.2532 36.975 16.1693 36.8366 16.1122C36.6983 16.0552 36.55 16.0259 36.4003 16.0262V16.0262Z"
				fill="#DC3545"
			/>
			<path
				d="M37.9219 8.32901H28.513V6.8895C28.5132 6.73975 28.484 6.59143 28.4268 6.45301C28.3697 6.3146 28.2858 6.1888 28.1801 6.08283C28.0743 5.97686 27.9486 5.89278 27.8103 5.83542C27.672 5.77806 27.5237 5.74853 27.3739 5.74854H20.6261C20.4763 5.74854 20.328 5.77805 20.1896 5.8354C20.0513 5.89275 19.9255 5.97681 19.8197 6.08277C19.7138 6.18873 19.6298 6.31452 19.5726 6.45294C19.5154 6.59136 19.486 6.73971 19.4861 6.8895V8.32901H10.0781C9.92843 8.32883 9.78018 8.35815 9.64185 8.41532C9.50351 8.47248 9.37781 8.55636 9.27193 8.66215C9.16604 8.76795 9.08206 8.89358 9.02478 9.03187C8.9675 9.17015 8.93805 9.31838 8.93811 9.46806V12.7954C8.93805 12.9451 8.9675 13.0933 9.02478 13.2316C9.08206 13.3699 9.16604 13.4955 9.27193 13.6013C9.37781 13.7071 9.50351 13.791 9.64185 13.8482C9.78018 13.9053 9.92843 13.9346 10.0781 13.9345H37.9219C38.0717 13.9348 38.22 13.9055 38.3584 13.8484C38.4967 13.7913 38.6225 13.7074 38.7284 13.6016C38.8343 13.4958 38.9183 13.3701 38.9755 13.2318C39.0328 13.0934 39.0621 12.9451 39.0619 12.7954V9.46806C39.0621 9.31834 39.0328 9.17006 38.9755 9.03172C38.9183 8.89337 38.8343 8.76769 38.7284 8.66187C38.6225 8.55605 38.4967 8.47218 38.3584 8.41506C38.22 8.35794 38.0717 8.3287 37.9219 8.32901V8.32901Z"
				fill="#DC3545"
			/>
		</svg>
	);
};
