import person1 from '../images/person1.webp';
import person2 from '../images/person2.webp';
import person3 from '../images/person3.webp';
import person4 from '../images/person4.webp';
import { t } from '../locales/translation';
import {
	Title,
	Column,
	Container,
	Name,
	Person,
	Profile,
	Role,
	TestimonialsWrapper,
	TestimonialWrapper,
	Text,
} from './TestimonialsAtoms';

const testimonials = [
	{
		text: 'Huge thank you to our driver Rodolfo Arciniegas. It was our first time using Taxi Ecuador and we were worried about the whole process. The whole journey was so smooth and comfortable.',
		image: person1,
		name: 'Rodolfo Arciniegas',
		role: 'Conductor',
	},
	{
		text: 'Really great service. Driver was punctual, kind & helpful. Cab very clean & comfortable. I will certainly be using this firm again for myself and for my sister.',
		image: person2,
		name: 'John David',
		role: 'Conductor',
	},
	{
		text: 'Excellent in all aspects, including a most helpful (and interesting) driver, Pedro Solano. Cab spotless and every precaution taken in relation to CV-19. Thank you. Highly recommended.',
		image: person3,
		name: 'Pedro Solano',
		role: 'Conductor',
	},
	{
		text: 'Thanks to Nelson Castro for making our Christmas trip to Cuenca extra special. Our 89 year old mom enjoyed herself! It was very much appreciated and a fantastic experience for the whole family.',
		image: person4,
		name: 'Nelson Castro',
		role: 'Conductor',
	},
];

const Testimonial: React.FC<{
	image: string;
	name: string;
	role: string;
	text: string;
}> = ({ image, name, role, text }) => {
	return (
		<TestimonialWrapper>
			<Text>{t(text)}</Text>
			<Profile>
				<Person src={image} alt="person" />
				<Column>
					<Name>{name}</Name>
					<Role>{role}</Role>
				</Column>
			</Profile>
		</TestimonialWrapper>
	);
};

export const Testimonials: React.FC = () => {
	return (
		<TestimonialsWrapper id="testimonials">
			<Title>{t('Testimonials')}</Title>
			<Container>
				{testimonials.map((item, i) => (
					<Testimonial
						key={i}
						image={item.image}
						name={item.name}
						role={item.role}
						text={item.text}
					/>
				))}
			</Container>
		</TestimonialsWrapper>
	);
};
