import es from './locale.es';

const lang = window.localStorage.getItem('lang') || 'es';

const tran = (key: string) => {
	if (lang === 'es') {
		const result = es[key];
		if (!result) {
			console.warn(key);
		}
		return result || key;
	}

	return key;
};

tran.lang = lang;

export const t = tran;
