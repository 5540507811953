import { FormEvent } from 'react';
import { t } from '../locales/translation';
import {
	About,
	Box,
	Contact,
	EmailAddress,
	EmailSVG,
	FacebookSVG,
	FooterWrapper,
	Form,
	Input,
	InstagramSVG,
	Link,
	List,
	PhoneNumber,
	PhoneSVG,
	QuickLinks,
	QuickLinksTitle,
	Row,
	Socials,
	Text,
	Textarea,
	Title,
	TwitterSVG,
	Button,
} from './FooterAtoms';

export const Footer: React.FC = () => {
	const handleSumbit = (ev: FormEvent<HTMLFormElement>) => {
		ev.preventDefault();

		const target = ev.target as HTMLFormElement;
		const [subjectInput, messageInput] = target.elements;
		const subject = subjectInput as HTMLInputElement;
		const message = messageInput as HTMLInputElement;
		const subjectvalue = subject.value;
		const messageValue = message.value;
		// eslint-disable-next-line no-restricted-globals
		location.href =
			'mailto:' +
			'taxiecuadorjd@gmail.com' +
			'?cc=' +
			'&subject=' +
			subjectvalue +
			'&body=' +
			messageValue;
		subject.value = '';
		message.value = '';
	};

	return (
		<FooterWrapper>
			<About>
				<Title>TAXI ECUADOR</Title>
				<Text>
					{t(
						'Taxi ecuador is a US based long distance travelling service used by locals and tourists alike to easily access the farthest reaches of our beautiful country.'
					)}
				</Text>
				<Row>
					<PhoneSVG />
					<PhoneNumber>704-369-3506</PhoneNumber>
				</Row>
				<Row>
					<EmailSVG />
					<EmailAddress>taxiecuadorjd@gmail.com</EmailAddress>
				</Row>
				<Socials>
					<Box
						href="https://www.facebook.com/profile.php?id=100071273253254"
						target="_blank"
						rel="noopener"
						aria-label="facebook page"
					>
						<FacebookSVG />
					</Box>
					<Box
						href="https://www.instagram.com/taxi_ecuador"
						target="_blank"
						rel="noopener"
						aria-label="instagram page"
					>
						<InstagramSVG />
					</Box>
					<Box
						href="https://twitter.com/TaxiEcuador"
						target="_blank"
						rel="noopener"
						aria-label="twitter page"
					>
						<TwitterSVG />
					</Box>
				</Socials>
			</About>
			<QuickLinks>
				<QuickLinksTitle>{t('Quick Links')}</QuickLinksTitle>
				<List>
					<Link href="#landing">{t('Landing')}</Link>
					<Link href="#about">{t('About')}</Link>
					<Link href="#pricing">{t('Pricing')}</Link>
					<Link href="#features">{t('Features')}</Link>
					<Link href="#testimonials">{t('Testimonials')}</Link>
					<Link href="/blog">{t('Blog')}</Link>
				</List>
			</QuickLinks>
			<Contact>
				<QuickLinksTitle>{t('Contact Us')}</QuickLinksTitle>
				<Form onSubmit={handleSumbit}>
					<Input
						placeholder={`${t('Your subject')}`}
						required
						minLength={5}
					/>
					<Textarea
						placeholder={t('Message...')}
						required
						minLength={5}
					></Textarea>
					<Button type="submit">{t('Send')}</Button>
				</Form>
			</Contact>
		</FooterWrapper>
	);
};
