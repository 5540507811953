import { t } from '../locales/translation';
import {
	CommunicationSVG,
	DiamondSVG,
	FastSVG,
	FeaturesWrapperLarge,
	IdeeaSVG,
	LargeFeatureContainer,
	Paragraph,
	SecuritySVG,
	SphereWrapper,
	Title,
} from './FeaturesAtoms';
export const Features: React.FC = () => {
	return (
		<FeaturesWrapperLarge id="features">
			<LargeFeatureContainer language={t.lang}>
				<SphereWrapper>
					<IdeeaSVG />
				</SphereWrapper>
				<Title>{t('AFFORDABLE PRICES')}</Title>
				<Paragraph>
					{t(
						'All of our rates are updated frequently and are carefullyconsidered to give you the best deals possible. Our goal is to get you where you want to be with the least hassle to you possible.'
					)}
				</Paragraph>
			</LargeFeatureContainer>
			<LargeFeatureContainer language={t.lang}>
				<SphereWrapper>
					<SecuritySVG />
				</SphereWrapper>
				<Title>{t('SECURITY')}</Title>
				<Paragraph>
					{t(
						'All of our drivers are licensed taxi drivers part of taxi conglomerates and undergo a background check before joining our roster. Your safety is our number one concern when we select who we let drive you around our beautiful country.'
					)}
				</Paragraph>
			</LargeFeatureContainer>
			<LargeFeatureContainer language={t.lang}>
				<SphereWrapper>
					<DiamondSVG />
				</SphereWrapper>
				<Title>{t('CLEAN')}</Title>
				<Paragraph>
					{t(
						'All of our taxi drivers are required to strictly abide by a code of cleanliness, our taxis are clean and accomodating for our passengers to experience the most comfortable travel experience.'
					)}
				</Paragraph>
			</LargeFeatureContainer>
			<LargeFeatureContainer language={t.lang}>
				<SphereWrapper>
					<FastSVG />
				</SphereWrapper>
				<Title>{t('EFFICIENCY')}</Title>
				<Paragraph>
					{t(
						'As soon as you send us an order, that order is immediately ran through our entire staff to find you the fastest service. We know your time is valuable and you want to make the most of your time in ecuador.'
					)}
				</Paragraph>
			</LargeFeatureContainer>
			<LargeFeatureContainer language={t.lang}>
				<SphereWrapper>
					<CommunicationSVG />
				</SphereWrapper>
				<Title>{t('COMMUNICATION')}</Title>
				<Paragraph>
					{t(
						'Once a taxi driver accepts your order, you will be emailed his contact information along with the order confirmation. This way, you are in constant connection with your driver.'
					)}
				</Paragraph>
			</LargeFeatureContainer>
		</FeaturesWrapperLarge>
	);
};
