import { keyframes } from '@emotion/react';
import styled from '@emotion/styled/macro';

export const gradient = keyframes`
0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
`;

export const TestimonialsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 500px;
	background: #fdcf11;
	background-size: 400% 400%;
	animation: ${gradient} 30s ease infinite;
	text-align: center;
	padding: 50px 30px;
	padding-bottom: 150px;
`;

export const Title = styled.h1`
	color: #1a1a1a;
	font-size: 48px;
	margin: 0;
	margin-bottom: 50px;
`;

export const TestimonialWrapper = styled.div`
	border-radius: 24px;
	background-color: #fff;
	max-width: 400px;
	padding: 20px 50px;
	min-height: 170px;
	transition: 0.5s;
	user-select: none;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
	&:hover {
		transform: translateY(-5%);
	}
	@media (max-width: 412px) {
		padding: 20px 20px;
	}
`;

export const Text = styled.p`
	text-align: left;
	font-weight: 600;
	font-size: 18px;
`;

export const Profile = styled.div`
	display: flex;
	align-items: center;
	text-align: left;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Name = styled.h2`
	margin: 0;
	color: #2541b2;
`;
export const Role = styled.h3`
	margin: 0;
`;

export const Person = styled.img`
	width: 56px;
	height: 56px;
	border-radius: 50%;
	margin-right: 16px;
`;

export const Container = styled.div`
	display: grid;
	grid-template-columns: auto auto;
	place-content: center;
	gap: 32px;
	@media (max-width: 1111px) {
		grid-template-columns: auto;
	}
`;
