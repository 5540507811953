import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 100vh;
	background-color: #fdcf11;
	@media (max-width: 500px) and (max-height: 810px) {
		max-width: 500px;
		height: 100%;
		border-radius: 0;
	}
	@media (max-width: 380px) {
		max-width: 500px;
		height: 100%;
		border-radius: 0;
	}
`;

export const CheckoutForm = styled.form`
	max-width: 600px;
	width: 100%;
	background-color: #fff;
	border-radius: 14px;
	box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
	padding: 32px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	@media (max-width: 500px) {
		max-width: 500px;
		height: 100%;
		border-radius: 0;
		box-shadow: none;
	}
	@media (max-width: 380px) {
		max-width: 500px;
		height: 100%;
		border-radius: 0;
		box-shadow: none;
	}
`;

export const FormField = styled.div``;

export const PayButton = styled.button`
	width: 100%;
	padding: 12px;
	border-radius: 9999px;
	background-color: #1a1a1a;
	outline: none;
	border: none;
	color: #fff;
	font-weight: 800;
	cursor: pointer;
	transition: 200ms;
	font-size: 18px;
	margin-top: 32px;
	&:disabled {
		background-color: #473c3c;
		cursor: default;
	}
`;

export const Select = styled.select`
	font-family: 'Dosis', sans-serif;
	font-weight: 600;
	font-size: 16px;
	width: 100%;
	text-align-last: center;
	text-align: center;
	-moz-text-align-last: center;
`;

export const Option = styled.option``;

export const List = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Detail = styled.p`
	margin: 0;
	&::before {
		content: '•';
		margin-right: 10px;
	}
	font-weight: 600;
	font-size: 18px;
`;

export const Highlight = styled.span`
	color: #2541b2;
`;

export const Label = styled.label`
	margin-bottom: 4px;
	font-size: 18px;
	font-weight: 600;
	color: #2541b2;
`;

export const CardContainer = styled.div`
	border: 1px solid #ced4da;
	padding: 16px;
	border-radius: 0.25rem;
`;

export const CheckoutError = styled.p`
	color: #dc3545;
	margin: 0 auto;
	font-weight: 600;
`;

export const Small = styled.div`
	color: #6c757d !important;
	margin-top: -20px;
	font-size: 0.875em;
	color: #6c757d;
`;

export const DateAndTime = styled.input``;

export const NumberInput = styled.input`
	width: 50px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	border: 1px solid #ced4da;
	padding: 0.375rem 0.75rem;
	padding-right: 0;
	border-radius: 0.25rem;
	height: 30px;
	margin: 5px 10px;
	font-family: sans-serif;
	font-size: 18px;
	font-weight: 600;
	color: #2541b2;
	::-webkit-inner-spin-button,
	::-webkit-outer-spin-button {
		opacity: 1;
	}
	&:focus {
		color: #2541b2;
		background-color: #fff;
		border-color: #86b7fe;
		outline: 0;
		box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
	}
`;
