export const Security: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<svg
			viewBox="0 0 91 91"
			className={className}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M45.55 41.2798C43.8747 41.2798 42.5166 42.6379 42.5166 44.3132C42.5164 45.5244 43.2283 46.5668 44.2547 47.0531V50.7406C44.2547 51.4558 44.8344 52.0357 45.5498 52.0357C46.2652 52.0357 46.845 51.456 46.845 50.7406V47.0531C47.8716 46.5668 48.5834 45.5244 48.5834 44.3132C48.5834 42.6379 47.2253 41.2798 45.55 41.2798Z" />
			<path d="M77.8721 19.7552C72.2967 17.549 67.8715 13.4856 65.4115 8.31388C64.7425 6.90747 63.2964 5.99854 61.7272 5.99854H29.2726C27.7035 5.99854 26.2575 6.90729 25.5885 8.31388C23.1286 13.4856 18.7032 17.549 13.1279 19.7552C11.564 20.3739 10.5536 21.8647 10.5536 23.553V31.4465C10.5536 41.7941 13.571 52.0157 19.2796 61.007C25.0437 70.0862 33.3962 77.6738 43.4334 82.9493L43.5969 83.0354C44.1928 83.3485 44.8464 83.5051 45.5001 83.5051C46.1534 83.5051 46.8071 83.3485 47.4027 83.0355L47.5668 82.9493C57.6041 77.6736 65.9564 70.0862 71.7207 61.007C77.4292 52.0156 80.4466 41.7939 80.4466 31.4465V23.553C80.4466 21.8645 79.4358 20.3739 77.8721 19.7552ZM77.8564 31.4467C77.8564 41.3016 74.9786 51.0432 69.534 59.6189C64.012 68.3165 55.999 75.5913 46.3621 80.6567L46.198 80.7428C45.761 80.9728 45.2392 80.9726 44.8019 80.7428L44.6384 80.6569C35.0011 75.5915 26.9882 68.3166 21.4659 59.6189C16.0214 51.0434 13.1435 41.3016 13.1435 31.4465V23.553C13.1435 22.9343 13.5114 22.3888 14.0807 22.1637C20.2677 19.7155 25.185 15.192 27.9273 9.4265C28.1692 8.91764 28.6972 8.58884 29.2724 8.58884H61.7274C62.3025 8.58884 62.8304 8.91764 63.0725 9.4265C65.8149 15.192 70.7325 19.7155 76.9192 22.1638C77.4885 22.389 77.8564 22.9345 77.8564 23.5532V31.4467Z" />
			<path d="M84.3656 43.7368C83.668 43.5789 82.9745 44.0167 82.817 44.7145C78.8727 62.1639 66.86 77.2967 49.8587 86.2326L45.9078 88.3093C45.6528 88.4433 45.3481 88.4433 45.0931 88.3093L41.1422 86.2326C24.1142 77.2827 12.0954 62.1237 8.16768 44.6428C8.0111 43.9451 7.31793 43.5062 6.62033 43.6632C5.92254 43.8199 5.48389 44.5127 5.64065 45.2105C9.73494 63.4338 22.2355 79.2214 39.9372 88.5252L43.888 90.6019C44.393 90.867 44.9466 90.9998 45.5004 90.9998C46.0541 90.9998 46.6081 90.8671 47.1128 90.6017L51.0637 88.525C68.7374 79.2358 81.2318 63.4756 85.3435 45.2853C85.501 44.5877 85.0632 43.8944 84.3656 43.7368Z" />
			<path d="M83.7352 14.819C76.8197 14.0894 71.3821 9.23526 70.5118 3.01491C70.2713 1.29604 68.7941 0 67.0758 0H23.9247C22.2066 0 20.7294 1.29604 20.4889 3.01491C19.6184 9.23543 14.1808 14.0894 7.26551 14.819C5.51323 15.0038 4.19177 16.4842 4.19177 18.2622V32.1689C4.19177 34.1897 4.29646 36.2328 4.50299 38.2415C4.57159 38.908 5.13394 39.4042 5.78978 39.4042C5.83404 39.4042 5.87883 39.4019 5.92379 39.3973C6.63526 39.3243 7.15283 38.6882 7.0796 37.9767C6.88214 36.0556 6.7819 34.1015 6.7819 32.1689V18.2622C6.7819 17.8205 7.11373 17.4393 7.53727 17.3947C15.6377 16.5401 22.0184 10.7744 23.0542 3.37375C23.1178 2.91964 23.4838 2.59012 23.9247 2.59012H67.0756C67.5166 2.59012 67.8829 2.91964 67.9463 3.37375C68.9818 10.7744 75.3627 16.54 83.4631 17.3947C83.8866 17.4393 84.2185 17.8205 84.2185 18.2622V32.1689C84.2185 34.0811 84.1203 36.0148 83.927 37.9168C83.8546 38.6283 84.3729 39.2638 85.0844 39.3362C85.7955 39.409 86.4314 38.8902 86.5039 38.1788C86.7062 36.1903 86.8086 34.1684 86.8086 32.1689V18.2622C86.8088 16.4842 85.4875 15.0038 83.7352 14.819Z" />
			<path d="M60.3336 32.3236H58.2042V26.1416C58.2042 19.164 52.5275 13.4873 45.5497 13.4873C38.572 13.4873 32.8952 19.164 32.8952 26.1416V32.3236H30.7659C28.4948 32.3236 26.6471 34.1713 26.6471 36.4424V54.9853C26.6471 57.2563 28.4948 59.1041 30.7659 59.1041H60.3336C62.6047 59.1041 64.4524 57.2563 64.4524 54.9853V36.4424C64.4524 34.1713 62.6047 32.3236 60.3336 32.3236ZM35.4855 26.1418C35.4855 20.5924 40.0003 16.0776 45.5499 16.0776C51.0994 16.0776 55.6142 20.5924 55.6142 26.1418V32.3238H35.4855V26.1418ZM61.8623 54.9853C61.8623 55.8281 61.1764 56.5139 60.3336 56.5139H30.7659C29.9229 56.5139 29.2372 55.8281 29.2372 54.9853V36.4424C29.2372 35.5996 29.9231 34.9137 30.7659 34.9137H60.3336C61.1764 34.9137 61.8623 35.5994 61.8623 36.4424V54.9853Z" />
		</svg>
	);
};
