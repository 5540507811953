import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { css } from '@emotion/css';
import {
	BlogWrapper,
	TitleWrapper,
	Title,
	Grid,
	Post,
	containerClassName,
	pageClassName,
	nextClassName,
	previousClassName,
} from './BlogAtoms';
import axios from 'axios';
import { LoaderWrapper } from './Post';
import Loader from 'react-loader-spinner';
import { AuthContext } from './AuthContext';
import { Button } from './components/PricingAtoms';
import styled from '@emotion/styled/macro';

const MAX_PER_PAGE = 6;

const anchorClass = css`
	text-align: center;
	flex-grow: 1;
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Editor = styled(Button.withComponent('a'))`
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	text-decoration: none;
	z-index: 100;
	border: 1px solid rgb(253, 207, 17);
	margin: 32px auto;
`;

export const Blog: React.FC = () => {
	const [currentPosts, setCurrentPosts] = useState<
		Array<{
			_id: string;
			headline: string;
			featuredImage: string;
			category: string;
		}>
	>([]);
	const { isLoggedIn } = useContext(AuthContext);
	const [count, setCount] = useState<number>(1);
	const pageChange = useCallback(
		async (selectedItem: { selected: number }) => {
			const offset = selectedItem.selected;
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACKEND_URL}/blog/post?offset=${
					MAX_PER_PAGE * offset
				}&limit=${MAX_PER_PAGE}`
			);
			setCount(data.count);
			setCurrentPosts(data.posts);
		},
		[]
	);
	useEffect(() => {
		pageChange({ selected: 0 });
	}, [pageChange]);

	return (
		<BlogWrapper>
			<TitleWrapper>
				<Title>Taxi Ecuador Blog</Title>
			</TitleWrapper>
			{currentPosts.length > 0 ? (
				<>
					<Grid>
						{currentPosts.map(post => (
							<Post
								headline={post.headline}
								featuredImage={post.featuredImage}
								category={post.category || 'Experiences'}
								id={post._id}
								key={post._id}
							/>
						))}
					</Grid>
					<ReactPaginate
						pageCount={Math.ceil(count / MAX_PER_PAGE)}
						pageRangeDisplayed={3}
						marginPagesDisplayed={3}
						containerClassName={containerClassName}
						pageClassName={pageClassName}
						onPageChange={pageChange}
						nextClassName={nextClassName}
						previousClassName={previousClassName}
						breakClassName={pageClassName}
						initialPage={0}
						previousLinkClassName={anchorClass}
						nextLinkClassName={anchorClass}
						activeClassName={css`
							background-color: #fdcf11;
							color: #1a1a1a !important;
						`}
						pageLinkClassName={anchorClass}
					/>
				</>
			) : (
				<LoaderWrapper>
					<Loader
						type="BallTriangle"
						color="#2541b2"
						height={80}
						width={80}
					/>
				</LoaderWrapper>
			)}
			{isLoggedIn && <Editor href="/blog/editor">Editor</Editor>}
		</BlogWrapper>
	);
};
