import { useState } from 'react';
import { t } from '../locales/translation';
import {
	ArrowUpSVG,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownText,
	DropdownToggle,
	EcuadorSVG,
	LanguagePickerWrapper,
	Span,
	UsaSVG,
	Row,
} from './LanguagePickerAtoms';

export const LanguagePicker = () => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<LanguagePickerWrapper>
			<Dropdown>
				<DropdownToggle
					id="dropdown-basic"
					isOpen={isOpen}
					onClick={() => setIsOpen(prev => !prev)}
				>
					<Row>
						{t.lang === 'en' ? <UsaSVG /> : <EcuadorSVG />}
						<DropdownText>{t.lang.toUpperCase()}</DropdownText>
						<ArrowUpSVG isOpen={isOpen} />
					</Row>
				</DropdownToggle>

				<DropdownMenu isOpen={isOpen}>
					<DropdownItem
						onClick={() => {
							if (t.lang === 'en') {
								setIsOpen(prev => !prev);
								return;
							}
							localStorage.setItem('lang', 'en');
							window.location.reload();
						}}
					>
						<UsaSVG />
						<Span>EN</Span>
					</DropdownItem>
					<DropdownItem
						onClick={() => {
							if (t.lang === 'es') {
								setIsOpen(prev => !prev);
								return;
							}
							localStorage.setItem('lang', 'es');
							window.location.reload();
						}}
					>
						<EcuadorSVG />
						<Span>ES</Span>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		</LanguagePickerWrapper>
	);
};
