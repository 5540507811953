import { t } from '../locales/translation';
import { Column, Description, Title, Wrapper } from './AboutAtoms';

const pricing = [
	{
		title: 'WHAT WE OFFER',
		description:
			'Taxi Ecuador is a long range driving service that allows its customers to easily experience the beautiful country of Ecuador in just a few clicks or taps.',
	},
	{
		title: 'HOW TO USE IT',
		description:
			'To use our service, select from one of our most popular destinations below, or select personalize to view our wide range of destinations.',
	},
	{
		title: 'WHY USE US?',
		description:
			"Our service is dedicated to long distance travel. This means that our drivers and Taxi's are trained and designed for your comfort and accommodation. Even if you are just driving locally, you  can always expect a comfortable driving experience with Taxi-Ecuador.",
	},
];

const Card: React.FC<{
	title: string;
	description: string;
}> = ({ title, description }) => {
	return (
		<>
			<Column>
				<Title>{t(title)}</Title>
			</Column>
			<Description>{t(description)}</Description>
		</>
	);
};

export const About: React.FC = () => {
	return (
		<Wrapper id="about">
			{pricing.map((item, i) => (
				<Card
					key={i}
					title={item.title}
					description={item.description}
				/>
			))}
		</Wrapper>
	);
};
