type Translations = {
	[key: string]: any;
};

const translations: Translations = {
	BEST: 'LA',
	TAXI: 'MEJOR OPCIÓN',
	EXPERIENCE: 'PARA VIAJAR EN TAXI',
	'Where do you want to go?': 'Donde quieres ir?',
	'AFFORDABLE PRICES': 'PRECIOS ASEQUIBLES',
	SECURITY: 'SEGURIDAD',
	EFFICIENCY: 'EFICIENCIA',
	COMMUNICATION: 'COMUNICACION',
	CLEAN: 'LIMPIEZA',
	Testimonials: 'Testimonios',
	Name: 'Nombre',
	'Email address': 'Correo electrónico',
	Address: 'Dirección',
	"We'll never share your email with anyone else.":
		'Nunca compartiremos su correo electrónico.',
	'The address where you want to be picked up from':
		'La dirección en la que desea que lo recojan',
	Destination: 'Destino',
	'Two way': 'Bidireccional',
	Details: 'Detalles',
	'Wait time will be paid to driver per hour in cash.':
		'El tiempo de espera se pagará al conductor por hora en efectivo.',
	Distance: 'Distancia',
	Duration: 'Duración',
	hours: 'horas',
	Pay: 'Pagar',
	Processing: 'Procesando',
	'Enter email': 'Ingrese correo electrónico',
	'Enter name': 'Ingrese su nombre',
	Phone: 'Teléfono',
	'Thank you for your order': 'Gracias por su orden',
	'You will receive and email shortly':
		'Recibirás un correo electrónico en breve',
	Continue: 'Continuar',
	'Quick Links': 'Enlaces Rápidos',
	Landing: 'Destino',
	About: 'Sobre nosotros',
	Pricing: 'Precios',
	Features: 'Características',
	'Contact Us': 'Contacta con Nosotros',
	'Your subject': 'Tu asunto',
	'Message...': 'Mensaje...',
	Send: 'Enviar',
	'BUY NOW': 'COMPRAR AHORA',
	'Milagro is a city located in Guayas, Ecuador and is the second largest city in the province of Guayas.':
		'Milagro es una ciudad ubicada en Guayas, Ecuador y es la segunda ciudad más grande de la provincia de Guayas.',
	'Salitre Canton is a canton of Ecuador, located in the Guayas Province. Its capital is the town of Salitre.':
		'El cantón Salitre es un cantón de Ecuador, ubicado en la provincia del Guayas. Su capital es la ciudad de Salitre.',
	'Cuenca (Santa Ana de los Ríos de Cuenca) is a city in southern Ecuador’s Andes mountains.':
		'Cuenca (Santa Ana de los Ríos de Cuenca) es una ciudad del sur de la cordillera de los Andes de Ecuador.',
	'Want to visit another city? Choose from the finest cities in Ecuador.':
		'¿Quiere visitar otra ciudad? Elija entre las mejores ciudades de Ecuador.',
	'View all': 'Ver mas',
	'Chauffeur time': 'Horas de Chofer',
	'Taxi Ecuador is a long range driving service that allows its customers to easily experience the beautiful country of Ecuador in just a few clicks or taps.':
		'Taxi Ecuador es un servicio de conducción de largo alcance que permite a sus clientes experimentar fácilmente el hermoso país de Ecuador con solo unos pocos clics.',
	'To use our service, select from one of our most popular destinations below, or select personalize to view our wide range of destinations.':
		'Para utilizar nuestro servicio, seleccione uno de nuestros destinos más populares, o seleccione personalizar para ver nuestra amplia selección de destinos.',
	"Our service is dedicated to long distance travel. This means that our drivers and Taxi's are trained and designed for your comfort and accommodation. Even if you are just driving locally, you  can always expect a comfortable driving experience with Taxi-Ecuador.":
		'Nuestro servicio está dedicado a viajes de larga distancia. Esto significa que nuestros conductores y taxis están capacitados y diseñados para su comodidad. Incluso si solo está conduciendo localmente, siempre puede esperar una experiencia de conducción cómoda con Taxi-Ecuador.',
	'Taxi ecuador is a US based long distance travelling service used by locals and tourists alike to easily access the farthest reaches of our beautiful country.':
		'Taxi Ecuador es un servicio de viajes de larga distancia con base en los Estados Unidos. Utilizado tanto por lugareños como por turistas para acceder fácilmente a los lugares más lejanos de nuestro hermoso país.',
	'All of our rates are updated frequently and are carefullyconsidered to give you the best deals possible. Our goal is to get you where you want to be with the least hassle to you possible.':
		'Todas nuestras tarifas se actualizan con frecuencia y son cuidadosamente consideradas para ofrecerle las mejores ofertas posibles. Nuestro objetivo es llevarlo a donde usted desea estar con la menor molestia posible.',
	'All of our drivers are licensed taxi drivers part of taxi conglomerates and undergo a background check before joining our roster. Your safety is our number one concern when we select who we let drive you around our beautiful country.':
		'Todos nuestros conductores son taxistas licenciados que forman parte de conglomerados de taxis y se someten a una verificación de antecedentes antes de unirse a nuestra lista. su seguridad es nuestra principal preocupación cuando seleccionamos a quién permitimos que lo lleve por nuestro hermoso país.',
	'All of our taxi drivers are required to strictly abide by a code of cleanliness, our taxis are clean and accomodating for our passengers to experience the most comfortable travel experience.':
		'Todos nuestros taxistas están obligados a cumplir estrictamente con un código de limpieza, nuestros taxis están limpios y son cómodos para que nuestros pasajeros tengan una experiencia cómoda.',
	'Once a taxi driver accepts your order, you will be emailed his contact information along with the order confirmation. This way, you are in constant connection with your driver.':
		'Una vez que un taxista acepta su pedido, se le enviará por correo electrónico su información de contacto junto con la confirmación del pedido. de esta manera estará en constante conexión con su conductor',
	'As soon as you send us an order, that order is immediately ran through our entire staff to find you the fastest service. We know your time is valuable and you want to make the most of your time in ecuador.':
		'Tan pronto como nos envíe un pedido, ese pedido se ejecutará inmediatamente a través de todo nuestro personal para encontrarle el servicio más rápido disponible. Sabemos que su tiempo es valioso y desea aprovechar al máximo su tiempo en Ecuador.',
	'WHAT WE OFFER': 'LO QUE OFRECEMOS',
	'HOW TO USE IT': 'CÓMO USARLO',
	'WHY USE US?': '¿POR QUÉ UTILIZARNOS?',
	'Excellent in all aspects, including a most helpful (and interesting) driver, Pedro Solano. Cab spotless and every precaution taken in relation to CV-19. Thank you. Highly recommended.':
		'Excelente en todos los aspectos, incluido un conductor de lo más servicial (e interesante), Pedro Solano. La cabina estaba impecable y se tomaron todas las precauciones en relación con la CV-19. Gracias. Muy recomendable.',
	'Thanks to Nelson Castro for making our Christmas trip to Cuenca extra special. Our 89 year old mom enjoyed herself! It was very much appreciated and a fantastic experience for the whole family.':
		'Gracias a Nelson Castro por hacer que nuestro viaje de Navidad a Cuenca sea muy especial. Nuestra madre de 89 años disfrutó mucho. Fue muy apreciado y una experiencia fantástica para toda la familia.',
	'Really great service. Driver was punctual, kind & helpful. Cab very clean & comfortable. I will certainly be using this firm again for myself and for my sister.':
		'Un servicio realmente bueno. El conductor fue puntual, amable y servicial. Taxi muy limpio y cómodo. Sin duda volveré a utilizar esta empresa para mí y para mi hermana.',
	'Huge thank you to our driver Rodolfo Arciniegas. It was our first time using Taxi Ecuador and we were worried about the whole process. The whole journey was so smooth and comfortable.':
		'Muchas gracias a nuestro conductor Rodolfo Arciniegas. Era nuestra primera vez usando Taxi Ecuador y estábamos preocupados por todo el proceso. Todo el viaje fue tan suave y cómodo.',
	'Pickup Time': 'Hora de recogida',
	Hourly: 'Por hora',
	'You can purchase additional chauffeur hours for an existing order, or purchase a chauffeur for driving around Guayaquil.':
		'Puede comprar horas adicionales de chófer para un pedido existente, o comprar un chófer para conducir por Guayaquil.',
	Blog: 'Blog',
	'Additional Destination': 'Destino adicional',
	'Drive time is separate': 'El tiempo de conducción es independiente',
	Optional: 'Opcional',
	'Customize your itinerary': 'Personaliza tu itinerario',
	'Get a Quote': 'Recibe una Cotización',
};

export default translations;
