import { useHistory } from 'react-router-dom';
import { t } from './locales/translation';
import React, { useState } from 'react';
import {
	CheckoutError,
	DateAndTime,
	FormField,
	Label,
	Option,
	PayButton as Submit,
	Select,
	Wrapper,
} from './CheckoutPageAtoms';
import { CheckoutForm as FormWrapper } from './CheckoutPageAtoms';
import { Destination } from './LocationMap';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import styled from '@emotion/styled/macro';
import { Textarea } from './components/FooterAtoms';

export const TextArea = styled(Textarea)`
	&::placeholder {
		font-size: 1rem;
		font-family: sans-serif;
		font-weight: 400;
	}
	font-family: sans-serif;
`;

export const Flex = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
`;

export const Plus = styled.div`
	background-color: #1a1a1a;
	color: #fff;
	width: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	height: 30px;
	border-radius: 4px;
	user-select: none;
	cursor: pointer;
`;

export const Minus = styled.div`
	margin-left: 16px;
	background-color: #1a1a1a;
	color: #fff;
	width: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	height: 30px;
	border-radius: 4px;
	user-select: none;
	cursor: pointer;
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 16px 0;
	gap: 16px;
`;

export const Form: React.FC = () => {
	const [phoneNumber, setPhoneNumber] = useState('');
	const [destinationNo, setDestinationNo] = useState(1);
	const [checkoutError, setCheckoutError] = useState('');
	const [isProcessing, setProcessingTo] = useState(false);

	const history = useHistory();

	const handleSumbit = async (ev: any) => {
		ev.preventDefault();

		const validRegex =
			/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

		const [nameInput, emailInput, addressInput, destinationInput] =
			ev.target.elements;

		const temp = [];
		for (let i = 1; i <= destinationNo; i++) {
			temp.push(ev.target.elements[3 + i].value);
		}
		const additionalDetails = ev.target.elements[4 + destinationNo].value;
		const pickupTime = ev.target.elements[5 + destinationNo].value;

		const data = {
			name: nameInput.value,
			email: emailInput.value,
			address: addressInput.value,
			destination:
				destinationInput.options[destinationInput.selectedIndex].text,
			additionalDestinations: temp,
			phoneNumber,
			additionalDetails,
			pickupDate: pickupTime.slice(0, pickupTime.indexOf('T')),
			pickupTime: pickupTime.slice(
				pickupTime.indexOf('T') + 1,
				pickupTime.length
			),
		};

		if (phoneNumber.length > 12 || phoneNumber.length < 11) {
			setCheckoutError('Invalid phone number');
			return;
		}

		if (!data.email.match(validRegex)) {
			setCheckoutError('Invalid email');
			return;
		}

		setProcessingTo(true);

		try {
			await axios.post(`${process.env.REACT_APP_BACKEND_URL}/form`, data);
			history.replace('/');
		} catch (err) {
			setProcessingTo(false);
			if (err.response) {
				setCheckoutError(err.response.data.message);
			} else {
				setCheckoutError(err.message);
			}
		}
	};
	return (
		<Wrapper>
			<link
				href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
				rel="stylesheet"
				integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
				crossOrigin="anonymous"
			/>

			<FormWrapper onSubmit={handleSumbit}>
				<FormField className="form-group">
					<Label htmlFor="name">{t('Name')}</Label>
					<input
						type="text"
						name="name"
						className="form-control"
						id="name"
						required
						autoFocus
						aria-describedby="nameHelp"
						placeholder={`${t('Enter name')}`}
					/>
				</FormField>
				<FormField className="form-group">
					<Label htmlFor="email">{t('Email address')}</Label>
					<input
						type="email"
						className="form-control"
						id="email"
						name="email"
						required
						aria-describedby="emailHelp"
						placeholder={`${t('Enter email')}`}
					/>
					<small id="emailHelp" className="form-text text-muted">
						{t("We'll never share your email with anyone else.")}
					</small>
				</FormField>
				<FormField className="form-group">
					<Label htmlFor="address">{t('Address')}</Label>
					<input
						type="text"
						name="address"
						className="form-control"
						id="address"
						aria-describedby="emailHelp"
						placeholder="1234 Main St"
					/>
					<small id="addressHelp" className="form-text text-muted">
						{t('The address where you want to be picked up from')}
					</small>
				</FormField>
				<FormField className="form-group">
					<Label htmlFor="destination">{t('Destination')}</Label>
					<Select
						id="destination"
						className="form-control"
						required
						name="destination"
						defaultValue=""
					>
						<Option disabled></Option>
						{Destination.map((destination, i) => (
							<Option key={i}>{destination}</Option>
						))}
					</Select>
				</FormField>
				<FormField className="form-group">
					<Flex>
						<Label htmlFor="additionalAddress">
							{t('Additional Destinations')}
						</Label>

						<Minus
							onClick={() => {
								if (destinationNo > 1) {
									setDestinationNo(prev => prev - 1);
								} else return;
							}}
						>
							-
						</Minus>
						<Plus
							onClick={() => {
								if (destinationNo < 5) {
									setDestinationNo(prev => prev + 1);
								} else return;
							}}
						>
							+
						</Plus>
					</Flex>
					<InputContainer>
						{Array.from({ length: destinationNo }).map((_, i) => (
							<input
								type="text"
								key={i}
								name={`additional-address${i + 1}`}
								className="form-control"
								id={`additional-address${i + 1}`}
								required
								aria-describedby="emailHelp"
								placeholder={`${t('Optional')} ${i + 1}`}
							/>
						))}
					</InputContainer>
				</FormField>
				<FormField className="form-group">
					<Label htmlFor="additionalAddress">
						{t('Additional Details')}
					</Label>
					<TextArea
						name="additionalAddress"
						className="form-control"
						id="additionalAddress"
						required
						aria-describedby="emailHelp"
						placeholder={t('Optional')}
					></TextArea>
					<small id="addressHelp" className="form-text text-muted">
						{t('Drive time is separate')}
					</small>
				</FormField>
				<FormField className="form-group">
					<Label htmlFor="inputState">{t('Pickup Time')}</Label>
					<DateAndTime
						id="inputState"
						className="form-control"
						type="datetime-local"
						name="pickup"
						required
					/>
				</FormField>
				<FormField className="form-group">
					<PhoneInput
						country={t.lang === 'en' ? 'us' : 'ec'}
						enableAreaCodes={true}
						onlyCountries={['ec', 'us']}
						enableSearch={true}
						inputClass={'phone-input'}
						value={phoneNumber}
						specialLabel={`${t('Phone')}`}
						onChange={phone => setPhoneNumber(phone)}
						inputProps={{
							name: 'phone',
							required: true,
						}}
					/>
				</FormField>
				{checkoutError && (
					<CheckoutError>{checkoutError}</CheckoutError>
				)}
				<Submit type="submit" disabled={isProcessing}>
					{isProcessing ? 'Processing...' : 'Submit'}
				</Submit>
			</FormWrapper>
		</Wrapper>
	);
};
