import { useMediaQuery } from 'react-responsive';
import { Features } from './components/Features';
import { Footer } from './components/Footer';
import { Landing } from './components/Landing';
import { FeaturesMobile } from './components/mobile/Features';
import { Pricing } from './components/Pricing';
import { About } from './components/About';
import { Testimonials } from './components/Testimonials';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CheckoutPage } from './CheckoutPage';
import { PaymentSuccess } from './PaymentSuccess';
import { LanguagePicker } from './components/LanguagePicker';
import { CheckoutPageTime } from './CheckoutPageTime';
import { t } from './locales/translation';
import { EditorComponent } from './EditorComponent';
import { Blog } from './Blog';
import { useContext, useEffect } from 'react';
import { Post } from './Post';
import { Login } from './Login';
import { AuthContext } from './AuthContext';
import { Form } from './Form';
import { Iterary } from './Iterary';

function App() {
	const isMobile = useMediaQuery({ maxWidth: 500 });
	useEffect(() => {
		document.querySelector('html')!.lang = t.lang;
	}, []);
	const { isLoggedIn } = useContext(AuthContext);
	return (
		<Switch>
			<Route path="/checkout/time">
				<LanguagePicker />
				<CheckoutPageTime />
			</Route>
			<Route path="/checkout">
				<LanguagePicker />
				<CheckoutPage />
			</Route>
			<Route path="/success">
				<LanguagePicker />
				<PaymentSuccess />
			</Route>
			<Route path="/blog/post">
				<Post />
			</Route>
			<Route path="/form">
				<LanguagePicker />
				<Form />
			</Route>
			{!isLoggedIn && (
				<Route path="/blog/admin">
					<Login />
				</Route>
			)}

			<Route path="/blog/editor">
				{isLoggedIn ? <EditorComponent /> : <Redirect to="/blog" />}
			</Route>
			<Route path="/blog">
				<Blog />
			</Route>
			<Route path="/">
				<LanguagePicker />
				<Landing />
				<About />
				<Pricing />
				<Iterary />
				{isMobile ? <FeaturesMobile /> : <Features />}
				<Testimonials />
				<Footer />

				<div className="elfsight-app-96b39211-a39b-4b8b-a610-8df3c92f664e"></div>
			</Route>
		</Switch>
	);
}

export default App;
