export const Twitter: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<svg
			className={className}
			viewBox="0 0 91 91"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0)">
				<path d="M91 17.2843C87.6159 18.7688 84.0101 19.7527 80.2506 20.2304C84.1181 17.9213 87.0699 14.2927 88.4577 9.919C84.8518 12.0689 80.8706 13.5874 76.6277 14.4349C73.2038 10.7892 68.3239 8.53125 63.0004 8.53125C52.6719 8.53125 44.3568 16.9146 44.3568 27.1919C44.3568 28.6707 44.4819 30.0926 44.7891 31.4462C29.2793 30.6897 15.5553 23.2562 6.33587 11.9324C4.72631 14.7249 3.78219 17.9213 3.78219 21.3623C3.78219 27.8233 7.10938 33.5506 12.0689 36.8664C9.07156 36.8095 6.13112 35.9393 3.64 34.5686C3.64 34.6255 3.64 34.6994 3.64 34.7734C3.64 43.8392 10.1067 51.3695 18.5867 53.1042C17.0682 53.5194 15.4131 53.7184 13.6955 53.7184C12.5011 53.7184 11.2954 53.6502 10.1636 53.3999C12.5807 60.788 19.4399 66.2196 27.5958 66.3959C21.2485 71.3611 13.1893 74.3527 4.46469 74.3527C2.93475 74.3527 1.46737 74.2844 0 74.0967C8.26394 79.4259 18.0578 82.4688 28.6195 82.4688C62.9492 82.4688 81.718 54.0312 81.718 29.3816C81.718 28.5569 81.6896 27.7607 81.6497 26.9701C85.3523 24.3425 88.4634 21.0608 91 17.2843Z" />
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="91" height="91" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
