export const Fast: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<svg
			viewBox="0 0 91 91"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path d="M21.5059 12.1748H7.99805C6.52445 12.1748 5.33203 13.3672 5.33203 14.8408C5.33203 16.3144 6.52445 17.5068 7.99805 17.5068H21.5059C22.9795 17.5068 24.1719 16.3144 24.1719 14.8408C24.1719 13.3672 22.9795 12.1748 21.5059 12.1748Z" />
			<path d="M21.5059 33.5029H7.99805C6.52445 33.5029 5.33203 34.6953 5.33203 36.1689C5.33203 37.6425 6.52445 38.835 7.99805 38.835H21.5059C22.9795 38.835 24.1719 37.6425 24.1719 36.1689C24.1719 34.6953 22.9795 33.5029 21.5059 33.5029Z" />
			<path d="M16.1738 22.8389H2.66602C1.19242 22.8389 0 24.0313 0 25.5049C0 26.9785 1.19242 28.1709 2.66602 28.1709H16.1738C17.6474 28.1709 18.8398 26.9785 18.8398 25.5049C18.8398 24.0313 17.6474 22.8389 16.1738 22.8389Z" />
			<path d="M85.668 36.1689H69.4942V25.5049C69.4942 20.8223 63.766 18.3567 60.3906 21.7365L39.0641 43.0631C36.9812 45.1459 36.9812 48.52 39.0641 50.6029L51.2903 62.8291L36.3981 77.7213C34.3152 79.8041 34.3152 83.1782 36.3981 85.2611C38.4808 87.3438 41.8551 87.344 43.9379 85.2611L62.6 66.599C64.6829 64.5161 64.6829 61.142 62.6 59.0591L50.3739 46.833L58.8301 38.3767V41.5009C58.8301 44.4455 61.2176 46.833 64.1622 46.833H85.668C88.6125 46.833 91 44.4455 91 41.5009C91 38.5564 88.6125 36.1689 85.668 36.1689Z" />
			<path d="M49.7905 9.07166C47.6712 7.66311 44.8594 7.9418 43.0629 9.73816L28.3998 24.4012C26.3169 26.4841 26.3169 29.8582 28.3998 31.9411C30.4827 34.024 33.857 34.0238 35.9398 31.9409L47.5125 20.3683L51.4109 23.1765L56.6196 17.9678C57.4908 17.0965 58.5185 16.4203 59.6423 15.8922L49.7905 9.07166Z" />
			<path d="M35.2942 54.3727C33.9432 53.0217 33.0414 51.359 32.566 49.561L4.4059 77.7211C2.32303 79.804 2.32303 83.1781 4.4059 85.2609C6.48859 87.3436 9.86288 87.3438 11.9458 85.2609L39.0641 58.1426L35.2942 54.3727Z" />
			<path d="M72.1602 20.1729C76.5774 20.1729 80.1582 16.592 80.1582 12.1748C80.1582 7.75761 76.5774 4.17676 72.1602 4.17676C67.743 4.17676 64.1621 7.75761 64.1621 12.1748C64.1621 16.592 67.743 20.1729 72.1602 20.1729Z" />
		</svg>
	);
};
