import styled from '@emotion/styled/macro';

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-right: 150px;
	color: #fff;
	min-width: 180px;
`;

export const Wrapper = styled.div`
	min-height: 500px;
	padding: 50px 20px;
	background-color: #1a1a1a;
	display: grid;
	grid-template-columns: auto auto;
	place-content: center;
	gap: 64px;
	text-align: center;
	user-select: none;
	@media (max-width: 1000px) {
		grid-template-columns: auto;
		${Column} {
			margin-right: 0;
		}
	}
`;

export const Title = styled.h1`
	margin: 0;
	margin-bottom: 16px;
	font-size: 48px;
	@media (max-width: 490px) {
		font-size: 42px;
	}
`;

export const Description = styled.div`
	max-width: 400px;
	background-color: #fdcf11;
	padding: 32px;
	font-size: 18px;
	border-radius: 4px;
	box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
	min-height: 100px;
	display: flex;
	align-items: center;
	min-height: 115px;
	transition: 200ms;
	&:last-of-type {
		margin-bottom: 64px;
	}
	@media (max-width: 490px) {
		min-height: 154px;
	}
`;
