import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';

export const AuthContext = createContext({
	isLoggedIn: false,
	refreshToken: () => {},
});
export const AuthProvider: React.FC = ({ children }) => {
	const [token, setToken] = useState(
		() => localStorage.getItem('token') || ''
	);

	useEffect(() => {
		const interceptor = axios.interceptors.response.use(
			r => r,
			err => {
				const response = err.response;
				if (response && response.status === 401) {
					localStorage.removeItem('token');
					alert('Authentication Token Expired. Please Login Again');
					setToken('');
					// eslint-disable-next-line no-restricted-globals
					location.reload();
				}
				return Promise.reject(err);
			}
		);
		const reqIntercept = axios.interceptors.request.use(function (config) {
			config.headers['X-Token'] = token;
			return config;
		});

		return () => {
			axios.interceptors.response.eject(interceptor);
			axios.interceptors.request.eject(reqIntercept);
		};
	}, [token]);
	const refreshToken = () => {
		setToken(localStorage.getItem('token') || '');
	};
	return (
		<AuthContext.Provider value={{ isLoggedIn: !!token, refreshToken }}>
			{children}
		</AuthContext.Provider>
	);
};
