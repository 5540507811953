import React, { useState } from 'react';
import {
	CardContainer,
	CheckoutError,
	CheckoutForm,
	DateAndTime,
	FormField,
	Label,
	NumberInput,
	PayButton,
	Wrapper,
} from './CheckoutPageAtoms';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { t } from './locales/translation';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

export const CheckoutPageTime: React.FC = () => {
	const [waitTime, setWaitTime] = useState(1);
	const [canCheckout, setCanCheckOut] = useState(false);
	const [checkoutError, setCheckoutError] = useState(false);
	const [isProcessing, setProcessingTo] = useState(false);

	const history = useHistory();
	const stripe = useStripe();
	const elements = useElements();

	const price = waitTime ? waitTime * 20 : '0.00';

	const handleCheckout = async (ev: any) => {
		ev.preventDefault();

		const date = ev.target.pickup.value;

		const billingDetailsServer = {
			name: ev.target.name.value.trim(),
			email: ev.target.email.value.trim(),
			address: ev.target.address.value.trim(),
			pickupDate: date.slice(0, date.indexOf('T')),
			pickupTime: date.slice(date.indexOf('T') + 1, date.length),
			language: t.lang,
			waitTime: waitTime,
			orderType: 'Chauffeur',
		};

		const billingDetails = {
			name: ev.target.name.value.trim(),
			email: ev.target.email.value.trim(),
			address: {
				line1: ev.target.name.value.trim(),
			},
		};

		setProcessingTo(true);

		try {
			const { data: clientSecret } = await axios.post(
				`${process.env.REACT_APP_BACKEND_URL}/payment_intents/hours`,
				{
					waitTime,
				}
			);
			if (clientSecret && elements && stripe) {
				const cardElement = elements.getElement(CardElement);
				const paymentMethodReq = await stripe.createPaymentMethod({
					type: 'card',
					card: cardElement!,
					billing_details: billingDetails,
				});

				if (paymentMethodReq.error) {
					throw paymentMethodReq.error;
				}

				const response = await axios.post(
					`${process.env.REACT_APP_BACKEND_URL}/billing-data`,
					{
						...billingDetailsServer,
						paymentMethodId: paymentMethodReq.paymentMethod!.id,
					}
				);
				if (response.data.message) {
					throw response.data.message;
				}

				const confirmation = await stripe.confirmCardPayment(
					clientSecret,
					{
						receipt_email: billingDetails.email,
						payment_method: paymentMethodReq.paymentMethod!.id,
					}
				);
				//send payment intent id

				if (confirmation.error) {
					throw confirmation.error;
				}

				history.replace('/success');
			} else {
				throw new Error('Something went wrong, please try again later');
			}
		} catch (err) {
			setProcessingTo(false);

			if (err.response) {
				setCheckoutError(err.response.data.message);
			} else {
				setCheckoutError(err.message);
			}
		}
	};

	return (
		<Wrapper>
			<link
				href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
				rel="stylesheet"
				integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
				crossOrigin="anonymous"
			/>
			<CheckoutForm onSubmit={handleCheckout}>
				<FormField className="form-group">
					<Label htmlFor="name">{t('Name')}</Label>
					<input
						type="text"
						name="name"
						className="form-control"
						id="name"
						required
						aria-describedby="nameHelp"
						placeholder={`${t('Enter name')}`}
						autoFocus
					/>
				</FormField>

				<FormField className="form-group">
					<Label htmlFor="email">{t('Email address')}</Label>
					<input
						type="email"
						className="form-control"
						id="email"
						name="email"
						required
						aria-describedby="emailHelp"
						placeholder={`${t('Enter email')}`}
					/>
					<small id="emailHelp" className="form-text text-muted">
						{t("We'll never share your email with anyone else.")}
					</small>
				</FormField>

				<FormField className="form-group">
					<Label htmlFor="address">{t('Address')}</Label>
					<input
						type="text"
						name="address"
						className="form-control"
						id="address"
						required
						aria-describedby="emailHelp"
						placeholder="1234 Main St"
					/>
					<small id="addressHelp" className="form-text text-muted">
						{t('The address where you want to be picked up from')}
					</small>
				</FormField>

				<FormField className="form-group">
					<Label htmlFor="inputState">{t('Pickup Time')}</Label>
					<DateAndTime
						id="inputState"
						className="form-control"
						type="datetime-local"
						name="pickup"
						required
					/>
				</FormField>
				<FormField>
					<Label htmlFor="hours">{t('Chauffeur time')}:</Label>
					<NumberInput
						type="number"
						name="hours"
						id="hours"
						max={5}
						min={1}
						value={waitTime}
						required
						onChange={ev => {
							if (
								ev.target.valueAsNumber > 5 ||
								ev.target.valueAsNumber < 1
							) {
								return;
							} else {
								setWaitTime(
									Number(ev.target.valueAsNumber.toFixed(0))
								);
							}
						}}
					/>
					{t('hours')}
				</FormField>
				<FormField className="form-group">
					<CardContainer>
						<CardElement
							onChange={ev => {
								if (ev.complete) {
									setCanCheckOut(true);
								}
							}}
						/>
					</CardContainer>
				</FormField>
				{checkoutError && (
					<CheckoutError>{checkoutError}</CheckoutError>
				)}
				<PayButton
					type="submit"
					disabled={!canCheckout || isProcessing}
				>
					{isProcessing
						? `${t('Processing')}`
						: `${t('Pay')} $` + price}
				</PayButton>
			</CheckoutForm>
		</Wrapper>
	);
};
