export const ArrowUp: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<svg
			viewBox="0 0 91 91"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path d="M1.45453 67.8824L4.43536 70.884C5.37348 71.8214 6.6216 72.3378 7.95554 72.3378C9.28873 72.3378 10.5383 71.8214 11.4764 70.884L45.4797 36.8793L79.5221 70.9217C80.4587 71.8591 81.7083 72.3748 83.0415 72.3748C84.3747 72.3748 85.625 71.8591 86.5624 70.9217L89.544 67.9387C91.4853 65.9988 91.4853 62.8389 89.544 60.899L49.0124 20.2217C48.0758 19.2851 46.8276 18.6251 45.4826 18.6251H45.4671C44.1331 18.6251 42.885 19.2858 41.9484 20.2217L1.45453 60.7888C0.516411 61.7255 0.0014801 63.0113 0 64.3445C0 65.6784 0.516411 66.9465 1.45453 67.8824Z" />
		</svg>
	);
};
