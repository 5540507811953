export const Phone: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<svg
			className={className}
			viewBox="0 0 91 91"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0)">
				<path d="M83.6982 59.7311C78.1268 59.7311 72.6564 58.8597 67.4725 57.1466C64.9323 56.2802 61.8096 57.075 60.2593 58.6673L50.0272 66.3914C38.161 60.0572 30.8516 52.7501 24.6039 40.973L32.1007 31.0075C34.0485 29.0624 34.7471 26.221 33.9101 23.555C32.1896 18.3438 31.3156 12.876 31.3156 7.30228C31.3158 3.27576 28.0401 0 24.0138 0H7.30204C3.27576 0 0 3.27576 0 7.30204C0 53.4537 37.5465 91 83.6982 91C87.7245 91 91.0002 87.7242 91.0002 83.698V67.0329C91 63.0068 87.7242 59.7311 83.6982 59.7311Z" />
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="91" height="91" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
