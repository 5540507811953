import { keyframes } from '@emotion/react';
import styled from '@emotion/styled/macro';

export const LandingVideo = styled.video`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export const Wrapper = styled.div`
	height: 100vh;
	position: relative;
`;

export const Overlay = styled.div`
	display: flex;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.6);
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const Title = styled.h1`
	font-size: 80px;
	color: #fff;
	margin: 0;
	text-align: center;
	@media (max-width: 412px) {
		font-size: 64px;
	}
	@media (max-width: 375px) {
		font-size: 56px;
	}
	@media (max-width: 320px) {
		font-size: 48px;
	}
`;
export const Subtitle = styled.h2`
	font-size: 24px;
	font-weight: 600;
	margin: 0;
	margin-top: 16px;
	color: #fff;
	text-align: center;
`;

export const MoveWheel = keyframes`
0%{
	opacity:0;
	transform:translateY(-1rem);
}

100%{
	opacity:1; 
	transform:translateY(1rem);
}`;

export const ArrowDown = keyframes`
0%{
	opacity:0;
}

25%{
	opacity:.25;
}

50%{
	opacity:.5;
}

75%{
	opacity:.75;
}

100%{
	opacity:1; 
}`;

export const ScrollDownWrapper = styled.a`
	position: absolute;
	bottom: 5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-height: 560px) {
		display: none;
	}
`;
export const Mouse = styled.div`
	width: 2.5rem;
	height: 5rem;
	border: 2px solid #fff;
	border-radius: 2rem;
	margin-bottom: 0.5rem;
	display: flex;
	& > span {
		width: 1rem;
		height: 1rem;
		background-color: #fff;
		display: block;
		border-radius: 50%;
		margin: auto;
		animation: ${MoveWheel} 1s linear infinite;
	}
`;
export const Arrow = styled.div`
	& > span {
		display: block;
		width: 1rem;
		height: 1rem;
		border: 2px solid transparent;
		border-right-color: #fff;
		border-bottom-color: #fff;
		transform: rotate(45deg);
		animation: ${ArrowDown} 0.5s alternate infinite;
	}
`;
