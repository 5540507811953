import styled from '@emotion/styled/macro';
import { Success } from './svgs/Success';

export const SuccessSVG = styled(Success)`
	width: 7em;
	height: 7em;
	fill: #28a745;
	transition: 200ms;
`;

export const Heading = styled.h1`
	color: #fff;
	font-size: 48px;
	margin: 0;
	margin-top: 32px;
	@media (max-width: 500px) {
		font-size: 32px;
	}
	@media (max-width: 340px) {
		font-size: 28px;
	}
`;

export const Text = styled.p`
	color: #ffff;
	font-size: 32px;
	margin-bottom: 32px;
	@media (max-width: 500px) {
		font-size: 24px;
	}
	@media (max-width: 340px) {
		font-size: 16px;
	}
`;

export const SuccessWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100vh;
	justify-content: center;
	align-items: center;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Button = styled.button`
	width: 150px;
	padding: 12px;
	border-radius: 9999px;
	background-color: #fff;
	outline: none;
	border: none;
	color: #000;
	font-weight: 800;
	cursor: pointer;
	transition: 200ms;
	&:hover {
		transform: scale(1.2);
	}
`;
