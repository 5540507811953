import { t } from './locales/translation';
import React from 'react';
import styled from '@emotion/styled/macro';
import camera from './images/camera.webp';
import { Link } from 'react-router-dom';

export const IteraryWrapper = styled.div`
	min-height: 400px;
	background-image: url(${camera});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	background-attachment: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 32px;
`;

export const Container = styled(Link)`
	max-width: 900px;
	width: 100%;
	min-height: 200px;
	background-color: #fff;
	border-radius: 4px;
	border: 3px solid #1a1a1a;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-decoration: none;
	transition: 200ms ease-in-out;
	color: #000;
	box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
	&:hover {
		transform: scale(1.01);
	}
`;

export const Title = styled.h1`
	margin: 16px 0;
	font-size: 42px;
	transition: 500ms;
	@media (max-width: 360px) {
		font-size: 32px;
	}
`;

export const Subtitle = styled.p`
	margin: 0;
	font-size: 24px;
	font-weight: 600;
`;

export const Iterary: React.FC = () => {
	return (
		<IteraryWrapper>
			<Container to="/form">
				<Title>{t('Customize your itinerary')}</Title>
				<Subtitle>{t('Get a Quote')}</Subtitle>
			</Container>
		</IteraryWrapper>
	);
};
