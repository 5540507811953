import styled from '@emotion/styled/macro';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { Button } from './components/PricingAtoms';
import { ButtonWrapper } from './EditorComponent';

const Wrapper = styled.div`
	width: 100%;
	height: 100vh;
	background-color: #1a1a1a;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Form = styled.form`
	max-width: 500px;
	width: 100%;
	background-color: #fff;
	border-radius: 4px;
	padding: 32px;
	display: flex;
	flex-direction: column;
	@media (max-width: 500px) {
		height: 100%;
		justify-content: center;
	}
`;

const Error = styled.p`
	text-align: center;
	color: red;
`;

export const Login: React.FC = () => {
	const [error, setError] = useState('');
	const history = useHistory();
	const { refreshToken } = useContext(AuthContext);
	const handleLogin = async (e: any) => {
		e.preventDefault();
		const credentials = {
			username: e.target.username.value.trim(),
			password: e.target.password.value.trim(),
		};
		try {
			const { data } = await axios.post(
				`${process.env.REACT_APP_BACKEND_URL}/blog/login`,
				credentials
			);
			localStorage.setItem('token', data.token);
			refreshToken();
			history.replace('/blog');
		} catch (err) {
			setError(err.response.data.message);
		}
	};
	return (
		<>
			<link
				href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
				rel="stylesheet"
				integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
				crossOrigin="anonymous"
			/>
			<Wrapper>
				<Form onSubmit={handleLogin}>
					<div className="mb-3">
						<label htmlFor="headline" className="form-label">
							Username
						</label>
						<input
							type="text"
							className="form-control"
							id="headline"
							placeholder="ex: John Doe"
							required
							name="username"
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="featured-image" className="form-label">
							Password
						</label>
						<input
							type="password"
							className="form-control"
							id="featured-image"
							name="password"
							placeholder="Cat123"
							required
						/>
					</div>
					{error && <Error>{error}</Error>}
					<ButtonWrapper>
						<Button>Login</Button>
					</ButtonWrapper>
				</Form>
			</Wrapper>
		</>
	);
};
