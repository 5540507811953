export const Email: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<svg
			className={className}
			viewBox="0 0 91 91"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M48.1322 56.9959C47.2727 57.533 46.3058 57.7479 45.4463 57.7479C44.5868 57.7479 43.6198 57.533 42.7603 56.9959L0 30.8884V65.5909C0 73.0041 6.01653 79.0206 13.4298 79.0206H77.5702C84.9835 79.0206 91 73.0041 91 65.5909V30.8884L48.1322 56.9959Z" />
			<path d="M77.5702 11.9795H13.4297C7.09091 11.9795 1.719 16.4919 0.429749 22.5084L45.5537 50.0126L90.5702 22.5084C89.281 16.4919 83.9091 11.9795 77.5702 11.9795Z" />
		</svg>
	);
};
