import styled from '@emotion/styled/macro';
import { ArrowUp } from '../svgs/ArrowUp';
import { Ecuador } from '../svgs/Ecuador';
import { Usa } from '../svgs/Usa';

export const LanguagePickerWrapper = styled.div`
	position: fixed;
	top: 5px;
	right: 10px;
	z-index: 10000;
`;

export const Dropdown = styled.div`
	display: flex;
	flex-direction: column;
	width: 150px;
	box-sizing: initial;
	position: relative;
	transition: 200ms;
	user-select: none;
`;
export const DropdownToggle = styled.div<{ isOpen: boolean }>`
	display: flex;
	cursor: pointer;
	border-radius: 14px;

	border-bottom-left-radius: ${props => props.isOpen && '0px'};
	border-bottom-right-radius: ${props => props.isOpen && '0px'};
	border-bottom: ${props => props.isOpen && '1px solid #fdcf11'};
	background-color: #1a1a1a;
`;

export const DropdownText = styled.span`
	color: #fff;
	font-size: 18px;
	font-weight: 600;
`;

export const DropdownItem = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`;
export const DropdownMenu = styled.div<{ isOpen: boolean }>`
	display: flex;
	flex-direction: column;
	background-color: #1a1a1a;
	padding: 5px;
	color: #fff;
	font-size: 18px;
	font-weight: 600;
	transition: 500ms;
	border-bottom-left-radius: 14px;
	border-bottom-right-radius: 14px;
	opacity: ${props => (props.isOpen ? '1' : '0')};
	visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
`;

export const baseIcon = styled.svg`
	width: 2em;
	height: 2em;
	margin-left: 10px;
`;

export const baseArrowIcon = styled.svg`
	width: 1em;
	height: 1em;
	fill: #fff;
	margin-right: 10px;
`;

export const EcuadorSVG = baseIcon.withComponent(Ecuador);
export const UsaSVG = baseIcon.withComponent(Usa);
export const ArrowUpSVG = styled(ArrowUp)<{ isOpen: boolean }>`
	width: 1em;
	height: 1em;
	fill: #fff;
	margin-right: 10px;
	transition: 300ms;
	transform: rotate(${props => (props.isOpen ? '360deg' : '180deg')});
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-grow: 1;
	padding: 5px;
`;

export const Span = styled.span`
	margin-left: 24px;
`;
