import { css } from '@emotion/css';
import styled from '@emotion/styled/macro';
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from './AuthContext';
import { Edit } from './svgs/Edit';
import { Trash } from './svgs/Trash';

export const BlogWrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
`;
export const TitleWrapper = styled.div`
	min-height: 300px;
	background-color: #fdcf11;
`;
export const Title = styled.h1`
	text-align: center;
	font-weight: 400;
	font-size: 56px;
	font-weight: bold;
	@media (max-width: 499px) {
		font-size: 42px;
		padding-top: 16px;
	}
`;
export const Grid = styled.div`
	display: grid;
	grid-template-columns: auto auto auto;
	margin-top: -128px;
	max-width: 1440px;
	width: 100%;
	margin: -164px auto 0 auto;
	place-content: center;
	gap: 32px;
	margin-bottom: 64px;
	@media (max-width: 1178px) {
		grid-template-columns: auto auto;
	}
	@media (max-width: 682px) {
		grid-template-columns: auto;
	}
`;

const PostWrapper = styled.div`
	min-height: 300px;
	min-width: 300px;
	max-width: 300px;
	width: 100%;
	background-color: #fdcf11;
	border-radius: 4px;
	box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
	position: relative;
`;

const FeaturedImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 4px;
	user-select: none;
	min-height: 193px;
	max-height: 193px;
`;
const ImageWrapper = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
`;
const Category = styled.div`
	padding: 8px;
	min-width: 130px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #000;
	color: #fff;
	font-weight: 600;
	font-size: 24px;
	position: absolute;
	bottom: -24px;
	user-select: none;
	border-radius: 4px;
`;
const Headline = styled.h1`
	font-weight: 600;
	font-size: 28px;
`;
const ReadMore = styled.a`
	text-decoration: none;
	color: #000;
	position: relative;
	width: fit-content;
	margin: 0 auto;
	font-size: 18px;
	:after {
		content: '';
		height: 3px;
		width: 0;
		opacity: 0;
		background: #000;
		position: absolute;
		left: 0;
		bottom: -3px;
		transition: 0.5s;
		border-radius: 7px;
	}
	&:hover:after {
		width: 100%;
		opacity: 1;
	}
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	padding: 24px;
	justify-content: space-between;
	flex-basis: 1;
`;

const ControlsWrapper = styled.div`
	position: absolute;
	top: 8px;
	right: 8px;
	display: flex;
	gap: 16px;
	z-index: 1;
`;

const TrashSVG = styled(Trash)`
	width: 3em;
	height: 2em;
	cursor: pointer;
`;
const EditSVG = styled(Edit)`
	width: 2em;
	height: 2rem;
	cursor: pointer;
`;

export const Post: React.FC<{
	headline: string;
	featuredImage: string;
	category: string;
	id: string;
}> = ({ headline, featuredImage, category, id }) => {
	const { isLoggedIn } = useContext(AuthContext);
	const handleDelete = async () => {
		// eslint-disable-next-line no-restricted-globals
		const anwser = confirm('Are you sure you want to delete this post?');
		if (anwser) {
			try {
				await axios.delete(
					`${process.env.REACT_APP_BACKEND_URL}/blog/post/${id}`
				);
				// eslint-disable-next-line no-restricted-globals
				location.reload();
			} catch (err) {
				console.log(err);
			}
		}
	};
	return (
		<PostWrapper>
			{isLoggedIn && (
				<ControlsWrapper>
					<a href={`/blog/editor?p=${id}`}>
						<EditSVG />
					</a>
					<TrashSVG onClick={handleDelete} />
				</ControlsWrapper>
			)}
			<ImageWrapper>
				<FeaturedImage src={featuredImage} />
				<Category>{category}</Category>
			</ImageWrapper>
			<TextWrapper>
				<Headline>{headline}</Headline>
				<ReadMore href={`/blog/post?p=${id}`}>READ MORE</ReadMore>
			</TextWrapper>
		</PostWrapper>
	);
};

export const containerClassName = css`
	display: flex !important;
	list-style: none;
	width: fit-content;
	margin: 32px auto;
	padding-inline-start: 0;
`;
export const pageClassName = css`
	position: relative;
	cursor: pointer;
	border: 1px solid #fdcf11;
	padding: 4px;
	color: #fff;
	display: flex;
	min-width: 24px;
	height: 24px;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	transition: 200ms;
	@media (max-width: 627px) {
		display: none;
	}
`;

export const nextClassName = css`
	cursor: pointer;
	position: relative;
	border: 1px solid #fdcf11;
	padding: 4px;
	color: #fff;
	display: flex;
	min-width: 100px;
	height: 24px;
	justify-content: center;
	align-items: center;
	margin-left: 16px;
	font-weight: 600;
	transition: 200ms;
	user-select: none;

	&:hover {
		color: rgba(255, 255, 255, 0.7);
	}
`;

export const previousClassName = css`
	cursor: pointer;
	border: 1px solid #fdcf11;
	position: relative;
	padding: 4px;
	color: #fff;
	display: flex;
	min-width: 100px;
	height: 24px;
	justify-content: center;
	align-items: center;
	margin-right: 16px;
	font-weight: 600;
	transition: 200ms;
	user-select: none;
	&:hover {
		color: rgba(255, 255, 255, 0.7);
	}
`;
