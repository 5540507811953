import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { Button } from './components/PricingAtoms';
import styled from '@emotion/styled/macro';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import htmlToDraft from 'html-to-draftjs';

export const EditorWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 32px;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	max-width: 1440px;
	width: 100%;
	margin: 0 auto;
	padding: 32px 16px;
`;

export const EditorComponent: React.FC = () => {
	const [editorState, setEditorState] = useState<EditorState>();
	const [id, setId] = useState<string | null>();
	const [postData, setPostData] = useState<{
		headline: string;
		content: string;
		category: string;
		featuredImage: string;
	} | null>(null);
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		document.querySelector('body')!.style.backgroundColor = '#fff';
		return () => {
			document.querySelector('body')!.style.backgroundColor = '#1a1a1a';
		};
	}, []);

	useEffect(() => {
		const search = location.search;
		const params = new URLSearchParams(search);
		const ID = params.get('p');
		setId(ID);
		if (ID) {
			axios
				.get(`${process.env.REACT_APP_BACKEND_URL}/blog/post/${ID}`)
				.then(response => {
					setPostData(response.data);
					const html = response.data.content;
					const contentBlock = htmlToDraft(html);
					if (contentBlock) {
						const contentState = ContentState.createFromBlockArray(
							contentBlock.contentBlocks
						);
						setEditorState(
							EditorState.createWithContent(contentState)
						);
					}
				});
		}
	}, [location]);

	const handleUpdate = (e: any) => {
		e.preventDefault();
		try {
			if (editorState) {
				const content = draftToHtml(
					convertToRaw(editorState.getCurrentContent())
				);
				const postData = {
					headline: e.target.headline.value,
					featuredImage: e.target.featured.value,
					category: e.target.category.value,
					content,
				};
				axios.put(
					`${process.env.REACT_APP_BACKEND_URL}/blog/post/${id}`,
					postData,
					{
						withCredentials: true,
					}
				);
				history.replace('/blog');
			} else {
				alert('The post cannot be empty.');
			}
		} catch (err) {
			alert(err.response.data.message);
		}
	};

	const handlePost = (e: any) => {
		e.preventDefault();
		try {
			if (editorState) {
				const content = draftToHtml(
					convertToRaw(editorState.getCurrentContent())
				);
				const postData = {
					headline: e.target.headline.value,
					featuredImage: e.target.featured.value,
					category: e.target.category.value,
					content,
				};
				axios.post(
					`${process.env.REACT_APP_BACKEND_URL}/blog/post`,
					postData,
					{
						withCredentials: true,
					}
				);
				history.replace('/blog');
			} else {
				alert('The post cannot be empty.');
			}
		} catch (err) {
			alert(err.response.data.message);
		}
	};

	return (
		<EditorWrapper>
			<link
				href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
				rel="stylesheet"
				integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
				crossOrigin="anonymous"
			/>
			<Editor
				editorClassName={css`
					min-height: 40vh;
					padding: 0 32px;
					cursor: text;
				`}
				wrapperClassName={css`
					max-width: 1440px;
					width: 100%;
					margin: 0 auto;
					border: 1px solid #f1f1f1;
				`}
				editorState={editorState}
				onEditorStateChange={setEditorState}
				placeholder="Edit your post"
			/>
			<Form onSubmit={postData ? handleUpdate : handlePost}>
				<div className="mb-3">
					<label htmlFor="headline" className="form-label">
						Headline
					</label>
					<input
						type="text"
						className="form-control"
						id="headline"
						placeholder="Enter headline"
						required
						name="headline"
						defaultValue={(postData && postData.headline) || ''}
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="featured-image" className="form-label">
						Featured Image
					</label>
					<input
						type="text"
						className="form-control"
						id="featured-image"
						placeholder="https://www.site.com/yourpicture"
						name="featured"
						defaultValue={
							(postData && postData.featuredImage) || ''
						}
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="category" className="form-label">
						Category
					</label>
					<input
						type="text"
						className="form-control"
						id="category"
						placeholder="Travel"
						name="category"
						defaultValue={(postData && postData.category) || ''}
						required
					/>
				</div>
				<ButtonWrapper>
					<Button>POST</Button>
				</ButtonWrapper>
			</Form>
		</EditorWrapper>
	);
};
