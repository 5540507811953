import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import {
	Destination,
	DestinationInfo,
	DestinationType,
	LocationMap,
} from './LocationMap';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import {
	CardContainer,
	CheckoutError,
	CheckoutForm,
	Detail,
	FormField,
	Highlight,
	Label,
	List,
	Option,
	PayButton,
	Select,
	Wrapper,
	NumberInput,
	DateAndTime,
} from './CheckoutPageAtoms';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { t } from './locales/translation';

export const CheckoutPage: React.FC = () => {
	const [destinationInfo, setDestinationInfo] = useState<
		DestinationInfo | undefined
	>(undefined);
	const [destination, setDestination] = useState('');
	const history = useHistory();
	const [phoneNumber, setPhoneNumber] = useState('');
	const [twoWay, setTwoWay] = useState(false);
	const [canCheckout, SetCanCheckOut] = useState(false);
	const [isProcessing, setProcessingTo] = useState(false);
	const [checkoutError, setCheckoutError] = useState('');
	const [waitTime, setWaitTime] = useState(1);

	const stripe = useStripe();
	const elements = useElements();

	const location = useLocation();
	const handleCheckout = async (ev: any) => {
		ev.preventDefault();
		const validRegex =
			/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

		const date = ev.target.pickup.value;
		const billingDetailsServer = {
			name: ev.target.name.value.trim(),
			email: ev.target.email.value.trim(),
			address: ev.target.address.value.trim(),
			pickupDate: date.slice(0, date.indexOf('T')),
			pickupTime: date.slice(date.indexOf('T') + 1, date.length),
			destination: destination,
			twoWay: twoWay,
			phoneNumber: phoneNumber,
			language: t.lang,
			waitTime: waitTime,
		};

		const billingDetails = {
			name: ev.target.name.value.trim(),
			email: ev.target.email.value.trim(),
			address: {
				line1: ev.target.name.value.trim(),
			},
			phone: phoneNumber,
		};

		if (
			billingDetailsServer.phoneNumber.length > 12 ||
			billingDetailsServer.phoneNumber.length < 11
		) {
			setCheckoutError('Invalid phone number');
			return;
		}

		if (!billingDetailsServer.email.match(validRegex)) {
			setCheckoutError('Invalid email');
			return;
		}

		setProcessingTo(true);

		try {
			const { data: clientSecret } = await axios.post(
				`${process.env.REACT_APP_BACKEND_URL}/payment_intents`,
				{
					twoWay: billingDetailsServer.twoWay,
					destination: billingDetailsServer.destination,
					waitTime,
				}
			);
			if (clientSecret && elements && stripe) {
				const cardElement = elements.getElement(CardElement);
				const paymentMethodReq = await stripe.createPaymentMethod({
					type: 'card',
					card: cardElement!,
					billing_details: billingDetails,
				});

				if (paymentMethodReq.error) {
					throw paymentMethodReq.error;
				}

				const response = await axios.post(
					`${process.env.REACT_APP_BACKEND_URL}/billing-data`,
					{
						...billingDetailsServer,
						paymentMethodId: paymentMethodReq.paymentMethod!.id,
					}
				);
				if (response.data.message) {
					throw response.data.message;
				}

				const confirmation = await stripe.confirmCardPayment(
					clientSecret,
					{
						receipt_email: billingDetails.email,
						payment_method: paymentMethodReq.paymentMethod!.id,
					}
				);
				//send payment intent id

				if (confirmation.error) {
					throw confirmation.error;
				}

				history.replace('/success');
			} else {
				throw new Error('Something went wrong, please try again later');
			}
		} catch (err) {
			setProcessingTo(false);

			if (err.response) {
				setCheckoutError(err.response.data.message);
			} else {
				setCheckoutError(err.message);
			}
		}
	};

	const handleChange = (ev: React.ChangeEvent) => {
		const target = ev.target as HTMLOptionElement;

		const destinationInfo = LocationMap.get(
			target.value as DestinationType
		);
		setDestination(target.value);
		setDestinationInfo(destinationInfo);
		setWaitTime(destinationInfo?.waitTime || 1);
	};
	useEffect(() => {
		const search = location.search;
		const params = new URLSearchParams(search);
		const destination = params.get('destination');
		if (destination) {
			const destinationInfo = LocationMap.get(
				destination as DestinationType
			);
			setDestination(destination);
			setDestinationInfo(destinationInfo);
			setWaitTime(destinationInfo?.waitTime || 1);
		}
	}, [location]);

	const price = destinationInfo
		? twoWay
			? destinationInfo.priceTwoWays + (waitTime ? waitTime * 20 : 0)
			: destinationInfo.priceOneWay
		: '0.00';

	return (
		<Wrapper>
			<link
				href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
				rel="stylesheet"
				integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
				crossOrigin="anonymous"
			/>
			<CheckoutForm onSubmit={handleCheckout}>
				<FormField className="form-group">
					<Label htmlFor="name">{t('Name')}</Label>
					<input
						type="text"
						name="name"
						className="form-control"
						id="name"
						required
						aria-describedby="nameHelp"
						autoFocus
						placeholder={`${t('Enter name')}`}
					/>
				</FormField>

				<FormField className="form-group">
					<Label htmlFor="email">{t('Email address')}</Label>
					<input
						type="email"
						className="form-control"
						id="email"
						name="email"
						required
						aria-describedby="emailHelp"
						placeholder={`${t('Enter email')}`}
					/>
					<small id="emailHelp" className="form-text text-muted">
						{t("We'll never share your email with anyone else.")}
					</small>
				</FormField>

				<FormField className="form-group">
					<Label htmlFor="address">{t('Address')}</Label>
					<input
						type="text"
						name="address"
						className="form-control"
						id="address"
						required
						aria-describedby="emailHelp"
						placeholder="1234 Main St"
					/>
					<small id="addressHelp" className="form-text text-muted">
						{t('The address where you want to be picked up from')}
					</small>
				</FormField>

				<FormField className="form-group">
					<Label htmlFor="destination">{t('Destination')}</Label>
					<Select
						id="destination"
						className="form-control"
						required
						name="destination"
						onChange={handleChange}
						value={destination}
					>
						<Option disabled></Option>
						{Destination.map((destination, i) => (
							<Option key={i}>{destination}</Option>
						))}
					</Select>
				</FormField>

				<FormField className="form-group">
					<Label htmlFor="pickupTime">{t('Pickup Time')}</Label>
					<DateAndTime
						id="pickupTime"
						className="form-control"
						required
						type="datetime-local"
						name="pickup"
					/>
				</FormField>

				<FormField className="form-group">
					<PhoneInput
						country={t.lang === 'en' ? 'us' : 'ec'}
						enableAreaCodes={true}
						onlyCountries={['ec', 'us']}
						enableSearch={true}
						inputClass={'phone-input'}
						value={phoneNumber}
						specialLabel={`${t('Phone')}`}
						onChange={phone => setPhoneNumber(phone)}
						inputProps={{
							name: 'phone',
							required: true,
						}}
					/>
				</FormField>
				<FormField className="form-check">
					<input
						type="checkbox"
						className="form-check-input"
						id="twoWay"
						checked={twoWay}
						onChange={() => setTwoWay(!twoWay)}
					/>
					<Label className="form-check-label" htmlFor="twoWay">
						{t('Two way')}
					</Label>
				</FormField>
				{destinationInfo &&
					(destinationInfo.kilometers ||
						(twoWay && destinationInfo.waitTime)) && (
						<FormField className="form-group">
							<Label>{t('Details')}</Label>
							<List>
								{destinationInfo.kilometers && (
									<Detail>
										{t('Distance')}:
										<Highlight>
											{' ' +
												destinationInfo.kilometers +
												' '}
										</Highlight>
										km
									</Detail>
								)}
								{twoWay && (
									<Detail>
										{t('Chauffeur time')}:
										<Label
											htmlFor="hours"
											hidden
											aria-hidden="true"
										></Label>
										<NumberInput
											type="number"
											id="hours"
											name="hours"
											max={5}
											min={1}
											value={waitTime}
											required
											onChange={ev => {
												if (
													ev.target.valueAsNumber >
														5 ||
													ev.target.valueAsNumber < 1
												) {
													return;
												} else {
													setWaitTime(
														Number(
															ev.target.valueAsNumber.toFixed(
																0
															)
														)
													);
												}
											}}
										/>
										{t('hours')}
									</Detail>
								)}
								{twoWay && (
									<small
										id="twoWayDisclaimer"
										className="form-text text-muted"
									>
										{t('The rate is 20$ per hour.')}
									</small>
								)}
							</List>
						</FormField>
					)}
				<FormField className="form-group">
					<CardContainer>
						<CardElement
							onChange={ev => {
								if (ev.complete) {
									SetCanCheckOut(true);
								}
							}}
						/>
					</CardContainer>
				</FormField>
				{checkoutError && (
					<CheckoutError>{checkoutError}</CheckoutError>
				)}
				<PayButton
					type="submit"
					disabled={!destinationInfo || !canCheckout || isProcessing}
				>
					{isProcessing
						? `${t('Processing')}`
						: `${t('Pay')} $` + price}
				</PayButton>
			</CheckoutForm>
		</Wrapper>
	);
};
