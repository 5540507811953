import styled from '@emotion/styled/macro';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useLocation } from 'react-router-dom';

const ContentWrapper = styled.div`
	max-width: 680px;
	width: 100%;
	background-color: #fff;
	margin: 0 auto;
	img {
		border-radius: 4px;
	}
	@media (max-width: 824px) {
		img {
			width: 100% !important;
			padding: 0;
		}
		padding: 32px 0;
		p,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		span,
		strong,
		em {
			padding: 0 16px;
		}
	}

	box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
	padding: 64px;
`;

export const LoaderWrapper = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	inset: 0;
`;

export const Post: React.FC = () => {
	const [post, setPost] = useState<
		| {
				createdAt: string;
				content: string;
				headline: string;
		  }
		| undefined
	>();
	const location = useLocation();
	useEffect(() => {
		const search = location.search;
		const params = new URLSearchParams(search);
		const id = params.get('p');
		axios
			.get(`${process.env.REACT_APP_BACKEND_URL}/blog/post/${id}`)
			.then(response => {
				setPost(response.data);
			});
	}, [location]);

	useEffect(() => {
		document.querySelector('body')!.style.backgroundColor = '#fdcf11';
		return () => {
			document.querySelector('body')!.style.backgroundColor = '#1a1a1a';
		};
	}, []);
	return (
		<div>
			{post ? (
				<>
					<ContentWrapper
						dangerouslySetInnerHTML={{ __html: post.content }}
					></ContentWrapper>
				</>
			) : (
				<LoaderWrapper>
					<Loader
						type="BallTriangle"
						color="#2541b2"
						height={80}
						width={80}
					/>
				</LoaderWrapper>
			)}
		</div>
	);
};
