export const Edit: React.FC<
	React.SVGProps<SVGSVGElement> & { className?: string }
> = ({ className, ...rest }) => {
	return (
		<svg
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			{...rest}
		>
			<path
				d="M32.948 10.816L37.184 15.05L32.948 10.816ZM35.672 7.08602L24.218 18.54C23.6262 19.131 23.2226 19.884 23.058 20.704L22 26L27.296 24.94C28.116 24.776 28.868 24.374 29.46 23.782L40.914 12.328C41.2582 11.9838 41.5312 11.5752 41.7175 11.1255C41.9038 10.6758 41.9997 10.1938 41.9997 9.70702C41.9997 9.22026 41.9038 8.73826 41.7175 8.28854C41.5312 7.83883 41.2582 7.43021 40.914 7.08602C40.5698 6.74183 40.1612 6.4688 39.7115 6.28252C39.2618 6.09624 38.7798 6.00037 38.293 6.00037C37.8062 6.00037 37.3242 6.09624 36.8745 6.28252C36.4248 6.4688 36.0162 6.74183 35.672 7.08602V7.08602Z"
				stroke="#FDCF11"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M38 30.0001V36.0001C38 37.061 37.5786 38.0784 36.8284 38.8286C36.0783 39.5787 35.0609 40.0001 34 40.0001H12C10.9391 40.0001 9.92172 39.5787 9.17157 38.8286C8.42143 38.0784 8 37.061 8 36.0001V14.0001C8 12.9393 8.42143 11.9218 9.17157 11.1717C9.92172 10.4215 10.9391 10.0001 12 10.0001H18"
				stroke="#FDCF11"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
