export const Diamond: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<svg
			viewBox="0 0 91 91"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0)">
				<path d="M90.6478 24.813L75.4814 6.61324C75.1933 6.26897 74.7671 6.06885 74.3165 6.06885H16.6839C16.2335 6.06885 15.8073 6.26755 15.519 6.61324L0.352648 24.813C-0.0250327 25.265 -0.106967 25.8943 0.143279 26.4266C0.393526 26.9589 0.928855 27.3002 1.51732 27.3002H89.4827C90.0712 27.3002 90.6065 26.9589 90.8568 26.4266C91.1058 25.8959 91.0254 25.2664 90.6478 24.813ZM4.7556 24.267L17.3938 9.1006H73.6052L86.2434 24.267H4.7556Z" />
				<path d="M46.903 7.00314C46.6679 6.436 46.1144 6.06738 45.5016 6.06738H16.6854C16.1424 6.06738 15.6419 6.35709 15.3705 6.82879C15.0991 7.30049 15.1006 7.87829 15.3752 8.3484L25.9916 26.5482C26.2282 26.953 26.6393 27.2276 27.1064 27.2882C27.1716 27.2959 27.2368 27.3003 27.3004 27.3003C27.7009 27.3003 28.0876 27.1427 28.3743 26.856L46.574 8.65623C47.0077 8.22256 47.1382 7.57028 46.903 7.00314ZM27.6189 23.3207L19.3244 9.10073H41.8389L27.6189 23.3207Z" />
				<path d="M90.8403 25.1101C90.5855 24.5929 90.0578 24.2654 89.4814 24.2654H1.516C0.939614 24.2654 0.41335 24.5929 0.15706 25.1101C-0.0978071 25.6273 -0.0386224 26.2446 0.311687 26.7026L44.2945 84.3352C44.5812 84.7113 45.0271 84.9327 45.5002 84.9327C45.9733 84.9327 46.4193 84.7113 46.7029 84.3352L90.6857 26.7026C91.036 26.2446 91.095 25.6273 90.8403 25.1101ZM45.5002 80.9167L4.58259 27.3003H86.4178L45.5002 80.9167Z" />
				<path d="M46.9468 82.9581L28.7471 25.3256C28.5469 24.6946 27.9629 24.2654 27.3001 24.2654H1.5171C0.940713 24.2654 0.414449 24.5929 0.158159 25.1101C-0.0967085 25.6273 -0.0375237 26.2446 0.312786 26.7026L44.2956 84.3352C44.5897 84.722 45.0403 84.9327 45.5013 84.9327C45.7379 84.9327 45.9775 84.8782 46.1989 84.7598C46.8528 84.4218 47.1681 83.6604 46.9468 82.9581ZM4.58226 27.3003H26.1884L41.4398 75.5965L4.58226 27.3003Z" />
				<path d="M75.6314 6.82879C75.3614 6.35709 74.8595 6.06738 74.3165 6.06738H45.5002C44.8875 6.06738 44.3339 6.436 44.0987 7.00314C43.8636 7.57028 43.9941 8.22256 44.4279 8.65623L62.6277 26.856C62.9127 27.1427 63.2995 27.3003 63.6999 27.3003C63.7651 27.3003 63.8304 27.2957 63.8956 27.2882C64.3612 27.2276 64.7738 26.9546 65.0103 26.5482L75.6268 8.3484C75.8998 7.87829 75.9014 7.30049 75.6314 6.82879ZM63.3814 23.3207L49.1613 9.10073H71.6758L63.3814 23.3207Z" />
				<path d="M90.8434 25.1136C90.5885 24.5964 90.0608 24.2688 89.4844 24.2688H63.7014C63.0386 24.2688 62.4532 24.698 62.2545 25.329L44.0547 82.9616C43.8333 83.6622 44.1487 84.4236 44.8024 84.7634C45.0239 84.8786 45.2635 84.9333 45.5 84.9333C45.9597 84.9333 46.41 84.7226 46.7058 84.3388L90.6886 26.7062C91.0391 26.2478 91.0981 25.6306 90.8434 25.1136ZM49.5603 75.5965L64.8117 27.3004H86.4178L49.5603 75.5965Z" />
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="91" height="91" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
